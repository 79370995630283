.aside-block {
    font-size: 14px;
    line-height: 20px;
    padding: 15px;
    overflow: hidden;
    background: $ebonyClay;

    @include media('<desktop') {
        padding: 10px;
    }

    @include media('<tablet') {
        margin: 0 -10px;
        padding: 15px;
    }
}

.aside-block-list {
    color: $white;
    padding: 5px 0 0;
    overflow: hidden;
    margin: 0;

    li {
        margin: 0 0 14px;
        display: flex;
        align-items: center;
    }

    .fa, .fas {
        font-size: 24px;
        color: $orangeSecondary;
        margin: 0 6px 0 0;
        width: 28px;
    }

    .aside-block-list-text {
        flex-grow: 1;
        flex-basis: 0;
    }
}

.aside-block-participant {
    background: $blueAlt;

    .aside-block-list {
        .fa, .fas {
            color: $white;
        }
    }

    .btn-link {
        @include link-color-underline($white);
    }
}

.aside-block-btn-wrap {
    text-align: center;
    margin: 10px 0 23px;

    .btn {
        min-width: 140px;
    }
}

.aside-block-map {
    font-size: 0;
    line-height: 0;
    width: 100%;
    overflow: hidden;
    margin: 22px 0 0;
}

.aside-block-participation-mention {
    @include text-color($white);
}
