@font-face {
    @include fontface(Roboto Slab, robotoslab-regular-webfont, 400, normal);
}

@font-face {
    @include fontface(Roboto Slab, robotoslab-bold-webfont, 700, normal);
}

@font-face {
    @include fontface(Open Sans, opensans-regular-webfont, 400, normal);
}

@font-face {
    @include fontface(Open Sans, opensans-bold-webfont, 700, normal);
}
