.notification-badge-container {
    position: relative;
    padding: 2px 5px;
}

.notification-badge {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $dangerRed;
    color: white;
    padding: 1px 5px;
    font-size: 10px;
    position: absolute;
    top: -5px;
    right: -5px;

    @include media('<desktop') {
        height: 15px;
        width: 15px;
        font-size: 8px;
        top: -3px;
        right: -3px;
    }

    @include media('<tablet') {
        height: 20px;
        width: 20px;
        font-size: 10px;
        top: -5px;
        right: -5px;
    }
}

.notification-badge-large-number {
    right: -5px;
}

@media only screen and (max-width: 1023px) {
    .notification-badge-container {
        color: #FFF;
    }
}
