.mobile-menu {
    @include media('<tablet') {
        position: absolute;
        right: 0;
        top: 10px;
    }
}

.mobile-menu-opener {
    @include size(40px);

    border: 1px solid $orangePrincipal;
    border-radius: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    z-index: 1;
    font-size: 22px;
    line-height: 1;
    text-decoration: none;

    @include media('<tablet') {
        display: flex;
    }

    .mobile-menu-active & {
        color: $white;
        border-color: $white;
    }
}

.mobile-menu-holder {
    @include media('<tablet') {
        @include animate(transform, 0.25s, ease-in-out);

        width: 80%;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: auto;
        transform: translateX(100%);
        background: rgba(0,0,0,$holderOpacity);
    }

    .mobile-menu-active & {
        transform: translateX(0);
    }
}
