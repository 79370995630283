.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active,
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
    color: $white;
    background-color: $orangePrincipal;
    border-color: $orangePrincipal;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: none;
    color: $darkGray;
    cursor: default;
    @include opacity(0.4);
}

.datepicker table tr td.old,
.datepicker table tr td.new {
    color: $darkGray;
    @include opacity(0.6);
}
