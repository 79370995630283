.search-results {
    overflow: hidden;
    margin: -20px 0 0;

    @include media('<tablet') {
        margin-top: -12px;
    }

    h2 {
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 15px;

        @include media('<desktop') {
            margin: 0 0 10px;
        }
    }

}

.search-results-map {
    width: 100%;
    height: 965px;
    overflow: hidden;

    @include media('<tablet') {
        height: 160px;
        width: auto;
        margin: 0 -5px 12px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }
}
