.user-connected-badge {
	align-content: space-between;
	align-items: center;
	font-size: 14px;

	a {
		font-size: 14px;
		text-decoration: none;
		color: $black;

		&:hover {
			color: $orangePrincipal;
			background-color: transparent;
		}
	}

	i {
		font-size: 26px;

		@include media('<desktop') {
			font-size: 20px;
		}
	}

	.dropdown-header,
	.dropdown-divider {
		cursor: default;
	}
}

.user-connected-badge-box {
	display: flex;
	align-items: center;
	position: relative;
	margin: 0 25px 0 0;

	@include media('<tablet') {
		margin: 0 0 20px 0;
		padding-top: 5px;
		justify-content: center;
		flex-direction: column;
	}
}

.user-connected-badge-imgs-container-invitation-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 110px;
	margin: 10px 10px 10px 0;
}

.user-connected-badge-identity {
	margin-top: 10px;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.user-connected-badge-imgs-container {
	width: 60px;
	position: relative;
	margin: 0 10px 0 0;

	@include media('<desktop') {
		margin: 0;
		width: 50px;
	}
}

.user-connected-badge-imgs-container-email {
	margin: 0;

	.user-connected-badge-img {
		margin: auto;
	}
}

.user-connected-badge-img {
	@include size(50px);

	overflow: hidden;
	border-radius: 100%;

	@include media('<desktop') {
		@include size(40px);
	}

	img {
		@include size(50px);

		border-radius: 100%;
		display: block;

		@include media('<desktop') {
			@include size(40px);
		}
	}
}

.user-connected-badge-company-logo {
	@include size(36px);

	overflow: hidden;
	border-radius: 100%;
	position: absolute;
	right: -2px;
	top: 24px;

	@include media('<desktop') {
		@include size(26px);
	}

	img {
		@include size(36px);

		border-radius: 100%;
		display: block;

		@include media('<desktop') {
			@include size(26px);
		}
	}
}

.user-connected-badge-info {
	flex-grow: 1;
	text-align: center;
	font-family: $roboto;
	padding-right: 25px;

	@include media('<desktop') {
		padding-right: 10px;
	}
}

.user-connected-badge-title {
	line-height: 20px;
	color: $extraDarkGray;
	display: block;
	margin: 0 0 2px;
	font-weight: 500;

	@include media('<desktop') {
		font-size: 12px;
		line-height: 18px;
	}
}

.user-connected-badge-dropdown-opener {
	color: $extraDarkGray;
	text-decoration: underline;
	cursor: pointer;
	font-size: 12px;

	@include media('<desktop') {
		font-size: 10px;
	}

	&:hover {
		text-decoration: none;
	}
}

.user-connected-badge-greeting {
	margin-top: 15px;
	color: $darkGray;
}
