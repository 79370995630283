.video-info-box {
    margin: 0 0 20px;
}

.video-box-container {
    width: 80%;
    margin: auto;

    @include media('<tablet') {
      width: 100%;
    }
}
