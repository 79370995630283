.articles-slider {
    position: relative;
    margin: 0 0 5px;
    min-height: 240px;

    @include media('<tablet') {
        margin: 0 0 10px;
    }

    .slick-list {
        padding: 0 120px 0 50px;

        @include media('<desktop') {
            padding: 0 80px;
        }
    }

    .slide {
        flex: 1;
        padding: 0 10px;

        @include media('<tablet') {
            padding: 0 5px;
        }
    }

    .slick-track {
        display: flex;

        &:before,
        &:after {
            display: none;
        }
    }

    + .btn {
        margin: 0 auto;
        max-width: 160px;
        display: block;
    }
}
