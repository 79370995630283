.block-detail {
    @include font-size(14px, 22px);

    position: relative;
    overflow: hidden;

    @include media('<tablet') {
        font-size: 13px;
    }

    h2 {
        color: $white;
        font-weight: 700;
    }

    p {
        margin: 0 0 14px;
    }

    .btn {
        padding: 5px 13px;
    }
}

.block-detail-img {
    max-width: inherit;
    width: 580px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @include media('<tablet') {
        width: 100%;
        position: static;
        transform: none;
    }
}

.block-detail-caption {
    color: $white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 11px 20px 20px;
    z-index: 2;

    @include media('<desktop') {
        padding: 10px 10px 20px;
    }

    @include media('<tablet') {
        position: static;
        background: $ardoise;
    }

    &:before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: -80px;
        background: linear-gradient(
                        to bottom,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(41, 56, 68, 0.95) 100%
        );
        content: "";
        z-index: -1;

        @include media('<tablet') {
            display: none;
        }
    }
}
