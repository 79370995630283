.simple-entity-box-picture-container {
    width: 30%;
    height: 75px;
    text-align: center;

    img {
        max-height: 100%;
    }
}

.simple-entity-box-info-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
