img {
    max-width: 100%;
}

h1 {
    @include media('<desktop') {
        font-size: 34px;
    }

    @include media('<tablet') {
        font-size: 14px;
    }

    &.h2 {
        font-size: 30px;
        font-weight: 700;
    }
}

h2 {
    @include media('<tablet') {
        font-size: 16px;
        font-weight: 700;
    }
}

a {
    transition: all 0.4s ease;
}

.wrapper {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.form-control {
    border: 1px solid $silver;
    border-radius: 4px;
    padding: 6px 10px;
    line-height: 1;

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.white-container {
    background: $white;
    padding: 15px;

    @include media('<desktop') {
        padding: 10px;
    }

    @include media('<tablet') {
        padding: 10px 12px;
    }

    h2 {
        margin: 0 0 15px;
        text-transform: uppercase;

        @include media('<desktop') {
            margin: 0 0 10px 5px;
        }
    }
}

.title-block {
    padding: 12px 20px;

    @include media('<tablet') {
        padding: 10px;
        margin: 0 -10px;
    }

    h1.h2 {
        margin: 0 0 2px;

        @include media('<desktop') {
            font-size: 24px;
        }

        @include media('<tablet') {
            font-size: 18px;
        }
    }
}

.main {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.container {
    max-width: 1200px;
}

.social-networks {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px -5px 0;

    li {
        padding: 0 5px;
    }

    a {
        @include size(36px);
        @include font-size(20px, 24px);

        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        border-radius: 100%;
        color: $ebonyClay;

        //overwriting link default for links with class btn
        text-decoration: none;

        &:hover {
            text-decoration: none;
            background: $orangePrincipal;
        }
    }
}

.progress {
    border: 1px solid $darkGray;
    overflow: visible;
}

.progress-bar {
    border-radius: 5px;
    position: relative;
}

.tab-content {
    padding-top: 8px;
}

.js-tab-hidden {
    display: block !important;
    left: -9999px !important;
    position: absolute !important;
    top: -9999px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    background: $orangeSecondary;
    border-color: $orangeSecondary;
}
