.motivate-carousel {
	position: relative;
	margin: 0 -8px 10px;

	@include media('<tablet') {
		margin: 0 -5px 10px;
	}

	.mask {
		position: relative;
		overflow: hidden;
		width: 100%;
		margin: 0 0 10px;
	}

	.slideset {
		width: 100% !important;
		white-space: nowrap;
		letter-spacing: -4px;
	}

	.slide {
		display: inline-block;
		vertical-align: top;
		letter-spacing: normal;
		white-space: normal;
		width: 16.666%;
		padding: 0 8px;

		@include media('<tablet') {
			width: 33.333%;
			padding: 0 5px;
		}
	}

	.btn-prev,
	.btn-next,
	.pagination {
		margin: 0 5px;
	}

	.btn-prev,
	.btn-next {
		width: 16px;
		height: 16px;
		border: 1px solid $gray;
		border-radius: 100%;
		color: $gray;
		display: flex;
		align-items: center;
		justify-content: center;

		.fas {
			font-size: 11px;
			line-height: 1;
		}
	}

	.pagination {
		font-size: 12px;
		line-height: 16px;

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: inline-block;
				vertical-align: middle;

				&.active {
					a {
						font-weight: 700;
					}
				}
			}

			a {
				width: 16px;
				height: 16px;
				color: $gray;
				display: block;
				text-align: center;
			}
		}
	}
}

.slider-controls {
	display: flex;
	align-items: center;
	justify-content: center;
}
