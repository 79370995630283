.main-menu {
	font: 14px/18px $roboto;
	margin: 0 -10px;

	@include media('<desktop') {
		margin: 0 -6px;
	}

	@include media('<tablet') {
		font-size: 18px;
		line-height: 36px;;
		margin: 0;
		padding: 60px 15px;
	}
}

.main-menu-element {
	padding: 0 11px;
	position: relative;

	@include media('<desktop') {
		padding: 0 6px;
	}

	@include media('<tablet') {
		padding: 0;
		text-align: center;
	}

}

.main-menu-element-first {
	padding-right: 20px;

	@include media('<desktop') {
		padding-right: 14px;
	}

	@include media('<tablet') {
		padding: 0;
	}

	&:after {
		position: absolute;
		right: 4px;
		top: -5px;
		bottom: -5px;
		content: '';
		background: $black;
		width: 1px;

		@include media('<desktop') {
			top: 0px;
			bottom: 0px;
		}

		@include media('<tablet') {
			display: none;
		}
	}
}

.main-menu-item {
	color: $black;
	text-decoration: none;

	@include media('<tablet') {
		color: $white;
		text-align: center;
	}

	i {
		@include media('<tablet') {
			font-size: 30px;
		}
	}

	&:hover {
		color: $orangePrincipal;
	}
}

.main-menu-divider {
    height: 0;
    overflow: hidden;
    border-top: 1px solid $white;
	width: 80%;
	margin: auto;
	@include vertical-margin(10px);
}

.main-menu-divider-label {
	color: $white;
	text-align: center;
	font-style: italic;
	cursor: none;
}
