.collapsible-caret-container {
    cursor: pointer;

    &[aria-expanded="true"] {
        .collapsible-caret-down {
            display: none;
        }

        .collapsible-caret-up {
            display: initial;
        }
    }
}

.collapsible-caret-up {
  display: none;
}
