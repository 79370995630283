.search-results-slider {
    margin: 0 -10px 10px;
    display: flex;
    flex-flow: row wrap;

    @include media('<desktop') {
        margin: 0 -5px 10px;
    }

    @include media('>=tablet') {
        overflow: auto;
        height: 965px;
    }

    .slick-list {
        @include media('<tablet') {
            padding: 0 80px;
        }
    }

    .slick-track {
        display: flex;

        @include media('>=tablet') {
            width: 100% !important;
            transform: none !important;
            flex-flow: row wrap;
        }
    }

    .slick-slide {
        padding: 0 3px;
        float: none;
        height: auto;

        @include media('>=tablet') {
            padding: 0 5px;
            width: 50% !important;
            margin: 0 0 10px;
        }

        @include media('>=desktop') {
            margin: 0 0 20px;
            padding: 0 10px;
        }

        &.slick-cloned {
            @include media('>=tablet') {
                display: none !important;
            }
        }
    }

    .slick-arrow {
        @include media('>=tablet') {
            display: none !important;
        }
    }
}
