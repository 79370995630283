a.btn {
    text-decoration: none;
}

.btn-primary {
    color: $white;
}

.btn-outline-primary:hover {
    color: $white;
}

.btn-secondary:hover {
    background: transparent;
    color: $elm;
    border-color: $elm;
}

.btn-secondary-elm {
    background: $elm;
    color: $white;
    border-color: $elm;

    &:hover {
        color: $elm;
        background: $white;
        border-color: $elm;
    }
}

.btn-outline-secondary-elm {
    background: transparent;
    color: $elm;
    border-color: $elm;

    &:hover {
        background-color: $elm;
        color: $white;
    }
}

.btn-outline-secondary-danger {
    background: transparent;
    color: $darkGray;
    border-color: $darkGray;

    &:hover {
        background: transparent;
        color: $orangePrincipalDark;
        border-color: $orangePrincipalDark;
    }
}

.btn-sm-block {
    @include media('<tablet') {
        display: block;
        width: 100%;
    }
}

//&.facebook {
//    background: $chambray;
//    color: $white;
//
//    &:hover {
//        background: darken($chambray, 20%);
//    }
//}
//
//&.youtube {
//    background: $valencia;
//    color: $white;
//
//    &:hover {
//        background: darken($valencia, 20%);
//    }
//}//nadjib//TODO: Update login page with these stylings
