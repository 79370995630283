.form-newsletter {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin: 8px 0 40px;
    padding: 0 35px 0 0;

    @include media('<tablet') {
        width: 220px;
    }

    .btn {
        padding: 4px;
        text-align: center;
        border-radius: 6px;
        height: 30px;
        width: 30px;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.form-newsletter-wrap {
    width: 100%;
}

.form-login {
    position: relative;
    overflow: hidden;

    label {
        color: $elm;
        font-weight: 700;
        text-transform: uppercase;
    }

    .form-control {
        margin: 0 0 10px;
    }

    .btn {
        margin: 0 0 10px;
    }
}
