.entity-selector-dropdown-container {
    .select2-results__option {
        color: $orangePrincipal;

        &.select2-results__option--highlighted {
            color: $white;
            background-color: $orangePrincipal;
        }
    }
}
