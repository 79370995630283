.member-element {
    @include media('<desktop') {
        text-align: center;
    }
}

.member-element-title {
    font-weight: 700;
    color: $extraDarkGray;
    text-transform: uppercase;
    font-size: $font-size-base;
}

.member-element-company-picture {
    height: 75px;
    max-width: 100px;
}
