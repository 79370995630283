.header-search {
    position: absolute;
    left: 0;
    top: 0;
}

.header-search-opener {
    @include size(40px);

    border: 1px solid $orangePrincipal;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 10px;
    top: 9px;
    z-index: 1;
    font-size: 22px;
    line-height: 1;
    text-decoration: none;

    .header-search-active & {
        color: $white;
        border-color: $white;
    }
}

.header-search-holder {
    @include animate(transform, 0.25s, ease-in-out);

    width: 80%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    transform: translateX(-100%);
    background: rgba(0,0,0,$holderOpacity);

    .header-search-active & {
        transform: translateX(0);
    }
}

.header-search-filter {
    overflow: hidden;
    width: 100%;
    padding: 15px 0;
    text-align: right;

    a {
        color: $white;
        text-decoration: underline;

        &:hover {
            color: $orangePrincipal;
        }
    }
}
