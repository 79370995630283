.session-info-box {
	position: relative;
	overflow: hidden;
	margin: 0 0 20px;

	@include media('<tablet') {
		margin: 0 -22px 15px;
		background: $white;
	}
}
