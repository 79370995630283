.session-block {
    overflow: hidden;
    margin: 0 0 20px;
    font-size: 14px;

    @include media('<tablet') {
        margin: 0 -10px 12px;
    }
}

.session-block-sub-title {
    font-size: 12px;
    line-height: 16px;
    font-family: $roboto;
    display: block;
    color: $orangePrincipal;
}

.session-block-list {
    font-size:14px;
    line-height: 18px;
    overflow: hidden;
    margin: 0 0 7px;
    display: flex;
    flex-flow: row wrap;

    .session-block-list-box {
        flex-grow: 1;
        flex-basis: 0;
    }

    li {
        width: 50%;
        display: flex;
        align-items: center;
        margin: 0 0 11px;

        @include media('<desktop') {
            width: 100%;
        }

        &:first-child {
            width: 100%;
        }

        p {
            margin: 0;
        }
    }
}

.session-block-info-list {
    font-size: 12px;
}

.session-block-organization-picture {
    max-height: 75px;
}
