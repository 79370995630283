// Smacss files
// ------------------------------
$breakpoints: (
    'xs-phone': 320px,
    'phone': 576px,
    'tablet': 768px,
    'desktop': 992px,
    'widescreen': 1200px,
);

// keywords
// ligatured operators ≥ ≤
$media-expressions: (
    'screen': 'screen',
    'print': 'print',
    'handheld': 'handheld',
    'landscape': '(orientation: landscape)',
    'portrait': '(orientation: portrait)',
    'retina2x':
    '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
    'retina3x':
    '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)',
);

@import '~include-media/dist/include-media';

@import 'vendor/bootstrap_custom';

@import './base/variables';
@import './base/functions';
@import './base/mixins';
@import './base/helpers';
@import './base/fonts';

@import './layout/buttons';
@import './layout/common';
@import './layout/footer';
@import './layout/modal';
@import './layout/header';
@import './layout/links';


body {
    min-width: $base-min-width;
    font-size: 14px;
}
