//colors
.text-orange-principal-dark {
    @include text-color($orangePrincipalDark);
}

.text-orange-principal {
    @include text-color($orangePrincipal);
}

.text-orange-principal-background {
    background-color: $orangePrincipal;
    @include text-color($white);
}

.text-blue-alt {
    @include text-color($blueAlt);
}

.text-white {
    @include text-color($white);
}

.text-white-background {
    background-color: $white;
}

.text-gray {
    @include text-color($gray);
}

.text-nobel-background {
    background-color: $nobel;
}

//links
.link-white-underline {
    @include link-color-underline($white);
}

.link-gray-underline {
    @include link-color-underline($gray);
}

.link-black-underline {
    @include link-color-underline($black);
}

.link-orange-principal-underline {
    @include link-color-underline($orangePrincipal);
}

.link-springwood-underline {
    @include link-color-underline($springWood);
}

.link-blue-alt {
    @include link-color($blueAlt);
}

.link-blue-alt-underline {
    @include link-color-underline($blueAlt);
}

.link-white {
    @include link-color($white);
}

.link-darkgray {
    @include link-color($darkGray);
}

//text-styles
.text-underlined {
    text-decoration: underline;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-underlined {
    text-decoration: underline;
}

.text-regular {
    font-size: 14px;
}

.text-extra-small {
    font-size: $font-size-extra-sm;
}

.text-small {
    font-size: $font-size-sm;
}

.text-base {
    font-size: $font-size-base;
}

.text-lg {
    font-size: $font-size-lg;
}

.text-font-weight-normal {
    font-weight: normal;
}

.text-crossed {
    text-decoration: line-through;
}

//borders
.border-1 {
    border-width: 0.25rem !important;
}

.border-elm {
    border-color: $elm !important;
}

.border-dark-gray {
    border-color: $darkGray !important;
}

//utilities
.max-h-150 {
    max-height: 150px;
}

.max-h-350 {
    max-height: 350px;
}

.max-w-300 {
    max-width: 300px;
}

.w-70 {
    width: 70%;
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.w-min-250 {
    min-width: 250px;
}

.opacity-60 {
    opacity: 0.6;
}

.text-opacity-70 {
    opacity: 0.7;
}

.font-style-normal {
    font-style: normal;
}
