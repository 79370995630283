.search-form {
    font: 14px/18px $open-sans;
    position: relative;
    background: rgba($black, 0.7);
    padding: 15px 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: $white;
    left: 50%;
    transform: translateX(-50%);

    @include media('<desktop') {
        left: auto;
        transform: none;
        width: auto;
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    @include media('<tablet') {
        padding: 10px 15px;
    }

    label {
        margin: 0 8px 0 8px;

        @include media('<tablet') {
            display: block;
            text-align: center;
            margin: 0 0 6px;
            font-size: 12px;

            &.search-form-label-inline-block-tablet {
                display: inline-block;
            }
        }
    }

    .form-control {
        width: 280px;

        @include media('<desktop') {
            width: 200px;
        }

        @include media('<tablet') {
            width: 100%;
            text-align: center;
        }
    }

    .select2.select2-container.select2-container--default {
        width: 280px !important;

        @include media('<desktop') {
            width: 200px !important;
        }

        @include media('<tablet') {
            width: 100% !important;
            text-align: center !important;
        }

        .select2-search.select2-search--inline {
            width: 280px;

            @include media('<desktop') {
                width: 200px !important;
            }

            @include media('<tablet') {
                width: 100% !important;
                text-align: center !important;
            }
        }
    }

    .btn {
        margin: 0 0 0 15px;

        @include media('<tablet') {
            margin: 15px 0 0 0;
            width: 100%;
        }
    }
}

.search-form-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.search-form-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include media('<tablet') {
        display: block;
        width: 100%;
        margin: 0 0 11px;
    }
}

.search-form-wrap-home-sport .select2-container .select2-selection--multiple {
    padding: 6px 0px;
}
