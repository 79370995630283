.nav-tabs {
    font: 700 14px/18px $roboto;
    text-transform: uppercase;
    overflow: hidden;
    border: 0;
    margin: 0;

    @include media('<desktop') {
        font-size: 12px;
    }

    @include media('<tablet') {
        font-size: 14px;
    }

    .nav-item {
        padding: 0 5px;
        flex-basis: 0;
        flex-grow: 1;

        @include media('<tablet') {
            flex-basis: 50%;
            width: 50%;
            padding: 0px;
            height: 55px;
            margin-bottom: 0px;
        }

        &:first-child {
            padding-left: 0;

            @include media('<tablet') {
                display: block;
            }
        }

        .nav-link {
            color: $white;
            background: $lagoon;
            opacity: 0.7;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            text-decoration: none;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            @include media('<tablet') {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            &:hover,
            &.active {
                color: $lagoon;
                background: $white;
                opacity: 1;
                border: 1px solid $lagoon;
            }
        }

        &:first-child {
            .nav-link {
                border-top-left-radius: 5px;
            }
        }

        &:nth-of-type(2) {
            .nav-link {
                border-top-right-radius: 5px;
            }
        }
    }
}
