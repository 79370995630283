.entity-picture-round {
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.entity-picture-round-extra-small {
    height: 27px;
    width: 27px;
}
