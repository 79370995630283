.footer {
    width: 100%;
    overflow: hidden;
    background: $ebonyClay;
    color: $white;
    padding: 18px 0;

    @include media('<tablet') {
        padding: 18px 20px;
    }

    h4 {
        margin: 0 0 2px;
        font-weight: 700;
        text-transform: uppercase;
    }

    p {
        margin: 0;
    }

    //overwriting link default for links with class btn
    a {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.footer-list {
    position: relative;
    margin: 0 0 20px;
}

.footer-list-link {
    color: $white;

    &:hover {
        color: $orangePrincipal;
    }
}
