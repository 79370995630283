.select2-container {
    .select2-selection--multiple,
    .select2-selection--single {
        //amine
        // height: $input-height;
        padding: $input-padding-y $input-padding-x;
        font-family: $input-font-family;
        @include font-size($input-font-size);
    }

    .select2-search {
        width: 100%;
    }

    .select2-search--inline .select2-search__field {
        margin-top: 0px;
        width: 100% !important;

        &::placeholder {
            color: $input-placeholder-color;
            opacity: 1;
        }

        @include media('<tablet') {
            text-align: center;
        }
    }

    .select2-selection--multiple .select2-selection__choice {
        margin-top: 0px;
    }

    .select2-selection--single {
        .select2-selection__arrow {
            height: 100%;
        }

        .select2-selection__rendered {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0px;
        }

        .select2-selection__clear {
            margin-right: 10px;
            font-size: 20px;
        }
    }
}
