/* Our Own Variables */

// Colors
$orangePrincipal:        #ff7963;
$orangePrincipalDark:    darken($orangePrincipal, 15%);
$orangeSecondary:        #ff8463;
$blueAlt:                #018b98;
$blueSecondary:          #7acfd6;
$blueSecondaryLightened: #e4f5f6;
$clair:                  #f6f3ec;
$moyen:                  #8b898a;
$ardoise:                #2a3845;
$black:                  #000;
$white:                  #fff;
$silver:                 #afafaf;
$nobel:                  #b7b6b6;
$springWood:             #f5f3eb;
$lagoon:                 #008b97;
$gray:                   #8b8a8a;
$grayAlt:                #909090;
$naturalGray:            #8e8d8b;
$ebonyClay:              #293844;
$chambray:               #3e5b9a;
$valencia:               #d33f30;
$elm:                    #197984;
$lightGray:              #f2f2f2;
$darkGray:               #939292;
$extraDarkGray:          #3b3a36;
$dangerRed:              #ff566f;

// Font Size
$formControlHeight: 30px;

// Font Weights
$boldFontWeight: 700;

// Opacities
$holderOpacity: 0.9;

// Font Size
$font-size-extra-sm: $font-size-base * .7 !default;

$btn-link-color: $orangePrincipal;
$btn-link-hover-color: $orangePrincipalDark;
