.theme-cyan {
    h2,
    .session-article-title,
    .session-article-item .fas,
    .progress-block-value,
    .progress-block-link {
        color: $lagoon;
    }

    .progress-bar {
        background: $lagoon;
    }
}
