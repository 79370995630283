.link-underline-on-hover {
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.link-no-underline-on-hover {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.link-blue {
    @include link-decoration($blueAlt, $blueSecondary);
}

.link-gray {
    @include link-decoration($grayAlt, $darkGray);
}
