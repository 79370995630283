.session-article {
    position: relative;
    overflow: hidden;
    height: 100%;
    background: $lightGray;
    cursor: pointer;
    display: block;

    //overwriting link default for links with class btn
    text-decoration: none;

    @include media('<tablet') {
        font-size: 10px;
    }

    .slick-active &:hover {
        border: 3px solid $elm;
        background-color: transparent;
        color: $elm;

        .session-article-title {
            text-decoration: underline;
        }
    }
}

.session-article-image-container {
    width: 100%;
    height: 110px;
    position: relative;

    @include  media('<tablet') {
        height: 200px;
    }

    @include  media('<desktop') {
        height: 130px;
    }
}

.session-article-sport-slider-paris-logo,
.session-article-sport-slider-company-logo {
    position: absolute;
    bottom: 5px;
    right: 5px;
    max-width: 45px;
    max-height: 45px;
}

.session-article-sport-slider-company-logo {
    border-radius: 100%;
}

.session-article-tag-cyan,
.session-article-tag-orange {
    font-size: 12px;
    line-height: 16px;
    color: $white;
    text-transform: uppercase;
    padding: 4px 7px;
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    align-items: center;

    .far {
        font-size: 22px;
        line-height: 1;
        margin: 0 10px 0 0;

        @include media('<tablet') {
            font-size: 18px;
        }
    }
}

.session-article-tag-cyan {
    background: $lagoon;
}

.session-article-tag-orange {
    background: $orangePrincipal;
}

.session-article-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.session-article-text-holder {
    padding: 10px 8px 7px;
    color: $black;
}

.session-article-list {
    margin: 0;
}

.session-article-title {
    @include font-size(14px, 18px);

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: $boldFontWeight;
    display: block;
    color: $elm;
    text-transform: uppercase;

    @include media('<tablet') {
        margin: 0 0 4px;
    }

    &:hover {
        color: $elm;
    }
}

.session-article-item {
    position: relative;
    padding: 2px 0 2px 20px;
    margin: 0 0 1px;

    .fas {
        font-size: 16px;
        line-height: 20px;
        color: $lagoon;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);

        @include media('<tablet') {
            font-size: 14px;
            top: 0;
            transform: none;
        }
    }
}

.session-article-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}
