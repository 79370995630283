.filter-options {
    font-size: 14px;
    line-height: 18px;
    color: $black;
    overflow: hidden;
    text-align: left;
    padding: 15px;

    @include media('<tablet') {
        padding: 0 0 5px;
        margin: 0 -10px 25px;
    }

    h2 {
        font-size: 15px;
        text-transform: uppercase;
        font-family: $open-sans;
        font-weight: 700;
        margin: 0 0 15px;

        @include media('<tablet') {
            font-size: 18px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 -5px;
        display: flex;
        flex-flow: row wrap;

        li {
            width: 100%;
            padding: 0 5px;
            margin: 0 0 10px;

            @include media('<tablet') {
                width: auto;
            }
        }

        .custom-control-label {
            line-height: 24px;
        }
    }

    .form-control {
        width: 100%;
        margin: 0 0 10px;
    }

}

.filter-options-link {
    font-size: 16px;
    text-decoration: underline;
}

.filter-options-points-wrap {
    position: relative;
    height: 16px;
}

.filter-options-points {
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    color: $gray;

    &:nth-child(2) {
        left: 16%;
    }

    &:nth-child(3) {
        left: 32%;
    }

    &:nth-child(4) {
        left: 48%;
    }

    &:nth-child(5) {
        left: 66%;
    }

    &:nth-child(6) {
        left: auto;
        right: 0;
    }
}
