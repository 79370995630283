.tab-section-link-container {
    position: absolute;
    right: 0px;
    top: -5px;
    font-style: italic;
    text-align: right;
    @include link-color-underline($gray);
}

.tab-section-link-container a {
    @include link-color-underline($gray);
}

.tab-section-team-picture {
    max-height: 50px;
}

.tab-section .tab-section-title {
    color: $lagoon;
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    @include media('<tablet') {
        margin-top: 3rem;
    }
}
