.form-control {
    &:not([readonly]):focus {
        &:focus,
        &:active {
            border: 1px solid $orangePrincipalDark;
        }
    }

    &[readonly] {
        cursor: initial;
    }
}

.input-group-append > .btn {
    border-top-right-radius: $border-radius-sm;
    border-bottom-right-radius: $border-radius-sm;
}
