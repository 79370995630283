.applicants-box {
    font-size: 10px;
    line-height: 16px;
    overflow: hidden;
    background: $springWood;
    padding: 10px 7px 5px;
    text-align: center;
    height: 210px;
    display: flex;
    flex-direction: column;

    @include media('<tablet') {
        font-size: 12px;
        padding: 10px 5px 5px;
        flex-direction: row;
        flex-wrap: wrap;
    }

    p {
        margin: 0;
    }

    &.disabled {
        .applicants-box-footer {
            background: $nobel;
            font-size: 10px;
            line-height: 12px;

            .applicants-box-subtitle {
                margin: 0;
            }

            input[type="checkbox"] {
                display: none;
            }
        }
    }
}

.applicants-box-pending {
    height: 240px;
}

.applicants-box-img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    @include size(75px);
    margin: auto;

    @include media('<tablet') {
        width: 40%;
        margin: 0;
        height: auto;
    }
}

.applicants-box-img-wrapper {
    position: relative;
    width: fit-content;
}

.applicants-box-img {
    @include size(75px);
    overflow: hidden;
    border-radius: 100%;
    margin: 0 auto 7px;

    @include media('<tablet') {
        @include size(100px);
        margin: 0;
    }

    img {
        @include full-width-img;
    }
}

.applicants-box-img-company-logo {
    @include size(36px);

    overflow: hidden;
    border-radius: 100%;
    position: absolute;
    right: -2px;
    top: 45px;

    @include media('<desktop') {
        @include size(26px);
        top: 50px;
    }

    @include media('<tablet') {
        @include size(40px);
        top: 65px;
    }

    img {
        border-radius: 100%;
        display: block;

        @include size(36px);

        @include media('<desktop') {
            @include size(26px);
        }

        @include media('<tablet') {
            @include size(40px);
        }
    }
}

.applicants-box-text {
    overflow: hidden;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include media('<tablet') {
        width: 60%;
    }
}

.applicants-box-identity {
    color: $lagoon;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.applicants-box-fake {
    background: $orangeSecondary;

    .applicants-box-identity {
        color: $white;
    }
}

.applicants-box-partner {
    background: $blueSecondary;
}

.applicants-box-footer {
    background: $lagoon;
    margin: 5px -7px -5px;
    color: $white;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    height: 30px;
    padding: 0 5px;

    @include media('<tablet') {
        font-size: 10px;
    }
}

.applicants-box-subtitle {
    margin-right: 12px;

    @include media('<tablet') {
        margin-right: 7px;
    }
}

.applicants-box-validation-actions {
    width: 100%;
    margin-top: 10px;
}

.applicants-box-validation-form {
    @include media('<tablet') {
        width: 80%;
        margin: auto;
    }
}

.applicants-box-not-showed-up {
    opacity: 0.7;
}

.applicants-box-not-showed-up-clicked {
    opacity: 1;
    border: 3px solid $blueAlt;
}
