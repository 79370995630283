.sport-picture-container {
    position: relative;
    width: fit-content;
    padding: 0 10px;
}

.sport-picture {
    width: 100%;
    height: 80%;
    min-height: 90px;
    min-width: 240px;
}

.sport-picture-container-extra-infos .sport-picture {
    -webkit-filter: grayscale(50%);
    -moz-filter: grayscale(50%);
    -o-filter: grayscale(50%);
    -ms-filter: grayscale(50%);
    filter: grayscale(50%);
}

.sport-picture-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    opacity: 1;
    z-index: 1;
    background: rgba(0,0,0,.5);
    padding: 5px;
    width: max-content;
}

.sport-picture-paris-logo,
.sport-picture-organization-logo {
    position: absolute;
    bottom: 10px;
    right: 15px;
    max-width: 35px;
    max-height: 35px;
}

.sport-picture-organization-logo {
    border-radius: 100%;
}
