.theme-orange {
    h2,
    .session-article-title,
    .session-article-item .fas,
    .progress-block-value,
    .progress-block-link {
        color: $orangePrincipal;
    }

    .progress-bar {
        background: $orangePrincipal;
    }
}
