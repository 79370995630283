.famed-box {
    @include border-solid(0.1rem, $ebonyClay);
    overflow: hidden;
    text-align: center;
    padding: 10px;

    @include media('<tablet') {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 18px;
    }

}

.famed-box-text {
    @include media('<tablet') {
        margin: 0 22px 0 0;
    }
}

.famed-box-title {
    font-family: $roboto;
    font-size: 12px;
    line-height: 16px;
    display: block;
    margin: 0 0 2px;
    text-transform: uppercase;
}

.famed-box-sub-title {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px;

    @include media('<tablet') {
        margin: 0;
    }
}

.famed-box-img {
    width: 94px;
    height: 94px;
    border-radius: 100%;
    margin: 0 auto 17px;
    overflow: hidden;

    @include media('<tablet') {
        margin: 0 0 0 22px;
    }

    img {
        @include full-width-and-height-img;
    }
}
