.news-list {
    margin: 0;
    height: 285px;
    overflow: auto;

    time {
        display: block;
        color: $naturalGray;
        text-transform: uppercase;
    }

    .news-list-title {
        margin: 0 0 10px 0;
        font-size: 14px;
        font-weight: 500;
    }

    p {
        margin: 0;
    }

    a {
        @include link-decoration($blueAlt, $blueSecondary);
    }
}

.news-list-item {
    position: relative;
    margin: 0 0 19px 0;
    line-height: 1.1;

    &:last-child {
        margin: 0;
    }
}

.news-list-announcement-container {
    display: block;
    @include vertical-margin(50px);
}
