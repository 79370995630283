.progress-block {
    overflow: hidden;
    max-width: 420px;
    margin-bottom: 48px !important;

    @include media('<tablet') {
        background: $white;
        max-width: inherit;
        padding: 0 10px 25px;
        margin: 0 !important;
    }

    h2 {
        font-weight: 700;
    }
}

.progress-block-textbox {
    margin: 0 0 12px;
}

.progress-block-img {
    width: 47px;
    margin: 0 15px 0 0;

    img {
        @include full-width-img;
    }
}

.progress-block-txt {
    flex-grow: 1;
    flex-basis: 0;
}

.progress-block-value {
    font-size: 12px;
    line-height: 20px;
    font-weight: 700;
    color: $orangeSecondary;
    position: absolute;
    top: 100%;
    right: 0;

    @include media('<tablet') {
        font-size: 10px;
    }
}

.progress-block-wrap {
    overflow: hidden;
}

.progress-block-points-wrap {
    position: relative;
    height: 16px;
}

.progress-block-points {
    font-size: 12px;
    line-height: 16px;
    position: absolute;
    left: 0;
    top: 0;
    color: $gray;

    @include media('<tablet') {
        font-size: 10px;
        line-height: 20px;
    }

    &:nth-child(2) {
        left: 23%;
    }

    &:nth-child(3) {
        left: 47%;
    }

    &:nth-child(4) {
        left: 72%;
    }

    &:nth-child(5) {
        left: auto;
        right: 0;
    }
}

.progress-block-link {
    display: inline-block;
    margin-top: 15px;
    text-decoration: underline;

    @include media('<tablet') {
        margin-top: 10px;
    }

    &:hover {
        text-decoration: none;
    }
}
