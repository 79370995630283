/* Our Own Variables */
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #0d6efd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #d63384;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #ff7963;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  background-color: #f2f2f2;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
  line-height: 1.2;
  color: #ff8463; }

h1, .h1 {
  font-size: 2.75rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.4rem + 1.8vw) ; } }

h2, .h2 {
  font-size: 1.125rem; }

h3, .h3 {
  font-size: 0.875rem; }

h4, .h4 {
  font-size: 0.75rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ff7963;
  text-decoration: underline; }
  a:hover {
    color: #ff3717;
    text-decoration: none; }

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

progress {
  vertical-align: baseline; }

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

main {
  display: block; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top; }
  .table th,
  .table td {
    padding: 0.5rem;
    border-bottom: 1px solid #dee2e6; }
  .table tbody {
    vertical-align: inherit; }
  .table thead th {
    vertical-align: bottom;
    border-bottom-color: #495057; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.25rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #ffd9d3; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #ffb9ae; }

.table-hover .table-primary:hover {
  background-color: #ffc3ba; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #ffc3ba; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #afafaf;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #afafaf;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-control::placeholder {
    color: #b7b6b6;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem; }

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem; }

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem; }

.form-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .form-select:focus {
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .form-select::-ms-expand {
    display: none; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.75em; }

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.125em;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1em;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/%3e%3c/svg%3e");
    background-size: 1em;
    border-color: #0d6efd; }
  .form-check-input[disabled] {
    pointer-events: none;
    filter: none;
    opacity: .5; }
    .form-check-input[disabled] ~ .form-check-label {
      opacity: .5; }

.form-check-label {
  margin-bottom: 0; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0, 0, 0, 0.25)'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    border-radius: 2em; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380bdff'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .form-file-input:focus ~ .form-file-label {
    border-color: #8bbafe;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-file-input:focus-within ~ .form-file-label {
    border-color: #8bbafe;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-file-input[disabled] ~ .form-file-label .form-file-text {
    background-color: #e9ecef; }

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: calc(1.5em + 0.75rem + 2px);
  border-color: #ced4da;
  border-radius: 0.25rem; }

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit; }

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit; }

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: none; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #bed8fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #bed8fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-ms-thumb {
        transition: none; } }
    .form-range::-ms-thumb:active {
      background-color: #bed8fe; }
  .form-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .form-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .form-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .form-range:disabled::-moz-range-track {
    cursor: default; }
  .form-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .form-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; } }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .form-select,
    .input-group > .form-control + .form-file,
    .input-group > .form-select + .form-control,
    .input-group > .form-select + .form-select,
    .input-group > .form-select + .form-file,
    .input-group > .form-file + .form-control,
    .input-group > .form-file + .form-select,
    .input-group > .form-file + .form-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-file .form-file-input:focus ~ .form-file-label {
    z-index: 3; }
  .input-group > .form-file .form-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .form-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .form-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .form-file {
    display: flex;
    align-items: center; }
    .input-group > .form-file:not(:last-child) .form-file-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .form-file:not(:first-child) .form-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #34ce57; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #28a745; }

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #e4606d; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #dc3545; }

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.btn {
  display: inline-block;
  font-weight: 700;
  line-height: 18px;
  color: #000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 5px 30px;
  font-size: 0.875rem;
  border-radius: 20px;
  transition: all 0.4s ease; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #000;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #ff7963;
  border-color: #ff7963; }
  .btn-primary:hover {
    color: #fff;
    background-color: #ff583d;
    border-color: #ff4d30; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #ff583d;
    border-color: #ff4d30;
    box-shadow: 0 0 0 0.2rem rgba(222, 108, 90, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #ff7963;
    border-color: #ff7963; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff4d30;
    border-color: #ff4223; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 108, 90, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #ff7963;
  border-color: #ff7963; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #ff7963;
    border-color: #ff7963; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 121, 99, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #ff7963;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ff7963;
    border-color: #ff7963; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 121, 99, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #ff7963;
  text-decoration: underline; }
  .btn-link:hover {
    color: #ff3717;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 25px; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 20px; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:hover,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 22.5px;
  padding-left: 22.5px; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 8px 9px; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid rgba(0, 139, 151, 0.8); }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #fff; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #008b97;
    background-color: #fff;
    border-color: #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  display: inline-block;
  padding-top: 8px;
  padding-bottom: 8px; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #024dbc;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #853f33;
  background-color: #ffe4e0;
  border-color: #ffd9d3; }
  .alert-primary .alert-link {
    color: #602e25; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 15px; } }

.progress {
  display: flex;
  height: 15px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #fff;
  border-radius: 15px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff8463;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 15px 15px; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #853f33;
  background-color: #ffd9d3; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #853f33;
    background-color: #ffc3ba; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #853f33;
    border-color: #853f33; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .tooltip-arrow, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .tooltip-arrow, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .tooltip-arrow::before, .bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .popover-arrow, .bs-popover-auto[x-placement^="top"] > .popover-arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .popover-arrow::before, .bs-popover-auto[x-placement^="top"] > .popover-arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .popover-arrow::after, .bs-popover-auto[x-placement^="top"] > .popover-arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .popover-arrow, .bs-popover-auto[x-placement^="right"] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .popover-arrow::before, .bs-popover-auto[x-placement^="right"] > .popover-arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .popover-arrow::after, .bs-popover-auto[x-placement^="right"] > .popover-arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .popover-arrow, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[x-placement^="bottom"] > .popover-arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .popover-arrow, .bs-popover-auto[x-placement^="left"] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .popover-arrow::before, .bs-popover-auto[x-placement^="left"] > .popover-arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .popover-arrow::after, .bs-popover-auto[x-placement^="left"] > .popover-arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #ff8463;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #ff7963; }
  .link-primary:hover, .link-primary:focus {
    color: #ff3717; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #494f54; }

.link-success {
  color: #28a745; }
  .link-success:hover, .link-success:focus {
    color: #19692c; }

.link-info {
  color: #17a2b8; }
  .link-info:hover, .link-info:focus {
    color: #0f6674; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ba8b00; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #a71d2a; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: #cbd3da; }

.link-dark {
  color: #343a40; }
  .link-dark:hover, .link-dark:focus {
    color: #121416; }

.embed-responsive {
  position: relative;
  width: 100%; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  @media (min-width: 576px) {
    .sticky-sm-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  @media (min-width: 768px) {
    .sticky-md-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  @media (min-width: 992px) {
    .sticky-lg-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }
  @media (min-width: 1200px) {
    .sticky-xl-top {
      position: sticky;
      top: 0;
      z-index: 1020; } } }

.sr-only,
.sr-only-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.5) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #ff7963 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mr-4 {
  margin-right: 1.5rem !important; }

.mr-5 {
  margin-right: 3rem !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.ml-4 {
  margin-left: 1.5rem !important; }

.ml-5 {
  margin-left: 3rem !important; }

.ml-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -3rem !important; }

.mr-n1 {
  margin-right: -0.25rem !important; }

.mr-n2 {
  margin-right: -0.5rem !important; }

.mr-n3 {
  margin-right: -1rem !important; }

.mr-n4 {
  margin-right: -1.5rem !important; }

.mr-n5 {
  margin-right: -3rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -3rem !important; }

.ml-n1 {
  margin-left: -0.25rem !important; }

.ml-n2 {
  margin-left: -0.5rem !important; }

.ml-n3 {
  margin-left: -1rem !important; }

.ml-n4 {
  margin-left: -1.5rem !important; }

.ml-n5 {
  margin-left: -3rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pr-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }

.pl-5 {
  padding-left: 3rem !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

.text-primary {
  color: #ff7963 !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

.text-info {
  color: #17a2b8 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #dc3545 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #343a40 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #000 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: #ff7963 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #343a40 !important; }

.bg-body {
  background-color: #f2f2f2 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.font-italic {
  font-style: italic !important; }

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important; }

.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mr-sm-4 {
    margin-right: 1.5rem !important; }
  .mr-sm-5 {
    margin-right: 3rem !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .ml-sm-4 {
    margin-left: 1.5rem !important; }
  .ml-sm-5 {
    margin-left: 3rem !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n1 {
    margin-right: -0.25rem !important; }
  .mr-sm-n2 {
    margin-right: -0.5rem !important; }
  .mr-sm-n3 {
    margin-right: -1rem !important; }
  .mr-sm-n4 {
    margin-right: -1.5rem !important; }
  .mr-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n1 {
    margin-left: -0.25rem !important; }
  .ml-sm-n2 {
    margin-left: -0.5rem !important; }
  .ml-sm-n3 {
    margin-left: -1rem !important; }
  .ml-sm-n4 {
    margin-left: -1.5rem !important; }
  .ml-sm-n5 {
    margin-left: -3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pr-sm-4 {
    padding-right: 1.5rem !important; }
  .pr-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .pl-sm-4 {
    padding-left: 1.5rem !important; }
  .pl-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .text-sm-justify {
    text-align: justify !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mr-md-4 {
    margin-right: 1.5rem !important; }
  .mr-md-5 {
    margin-right: 3rem !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .ml-md-4 {
    margin-left: 1.5rem !important; }
  .ml-md-5 {
    margin-left: 3rem !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n1 {
    margin-right: -0.25rem !important; }
  .mr-md-n2 {
    margin-right: -0.5rem !important; }
  .mr-md-n3 {
    margin-right: -1rem !important; }
  .mr-md-n4 {
    margin-right: -1.5rem !important; }
  .mr-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n1 {
    margin-left: -0.25rem !important; }
  .ml-md-n2 {
    margin-left: -0.5rem !important; }
  .ml-md-n3 {
    margin-left: -1rem !important; }
  .ml-md-n4 {
    margin-left: -1.5rem !important; }
  .ml-md-n5 {
    margin-left: -3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pr-md-4 {
    padding-right: 1.5rem !important; }
  .pr-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .pl-md-4 {
    padding-left: 1.5rem !important; }
  .pl-md-5 {
    padding-left: 3rem !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .text-md-justify {
    text-align: justify !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mr-lg-4 {
    margin-right: 1.5rem !important; }
  .mr-lg-5 {
    margin-right: 3rem !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .ml-lg-4 {
    margin-left: 1.5rem !important; }
  .ml-lg-5 {
    margin-left: 3rem !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n1 {
    margin-right: -0.25rem !important; }
  .mr-lg-n2 {
    margin-right: -0.5rem !important; }
  .mr-lg-n3 {
    margin-right: -1rem !important; }
  .mr-lg-n4 {
    margin-right: -1.5rem !important; }
  .mr-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n1 {
    margin-left: -0.25rem !important; }
  .ml-lg-n2 {
    margin-left: -0.5rem !important; }
  .ml-lg-n3 {
    margin-left: -1rem !important; }
  .ml-lg-n4 {
    margin-left: -1.5rem !important; }
  .ml-lg-n5 {
    margin-left: -3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pr-lg-4 {
    padding-right: 1.5rem !important; }
  .pr-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .pl-lg-4 {
    padding-left: 1.5rem !important; }
  .pl-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .text-lg-justify {
    text-align: justify !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mr-xl-4 {
    margin-right: 1.5rem !important; }
  .mr-xl-5 {
    margin-right: 3rem !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .ml-xl-4 {
    margin-left: 1.5rem !important; }
  .ml-xl-5 {
    margin-left: 3rem !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n1 {
    margin-right: -0.25rem !important; }
  .mr-xl-n2 {
    margin-right: -0.5rem !important; }
  .mr-xl-n3 {
    margin-right: -1rem !important; }
  .mr-xl-n4 {
    margin-right: -1.5rem !important; }
  .mr-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n1 {
    margin-left: -0.25rem !important; }
  .ml-xl-n2 {
    margin-left: -0.5rem !important; }
  .ml-xl-n3 {
    margin-left: -1rem !important; }
  .ml-xl-n4 {
    margin-left: -1.5rem !important; }
  .ml-xl-n5 {
    margin-left: -3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pr-xl-4 {
    padding-right: 1.5rem !important; }
  .pr-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .pl-xl-4 {
    padding-left: 1.5rem !important; }
  .pl-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .text-xl-justify {
    text-align: justify !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* Our Own Variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

@font-face {
  font-family: Roboto Slab;
  src: url("../fonts/robotoslab-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/robotoslab-regular-webfont.woff2") format("woff2"), url("../fonts/robotoslab-regular-webfont.woff") format("woff"), url("../fonts/robotoslab-regular-webfont.svg") format("svg"), url("../fonts/robotoslab-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Roboto Slab;
  src: url("../fonts/robotoslab-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/robotoslab-bold-webfont.woff2") format("woff2"), url("../fonts/robotoslab-bold-webfont.woff") format("woff"), url("../fonts/robotoslab-bold-webfont.svg") format("svg"), url("../fonts/robotoslab-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: Open Sans;
  src: url("../fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans-regular-webfont.woff") format("woff"), url("../fonts/opensans-regular-webfont.svg") format("svg"), url("../fonts/opensans-regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: Open Sans;
  src: url("../fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../fonts/opensans-bold-webfont.woff") format("woff"), url("../fonts/opensans-bold-webfont.svg") format("svg"), url("../fonts/opensans-bold-webfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

a.btn {
  text-decoration: none; }

.btn-primary {
  color: #fff; }

.btn-outline-primary:hover {
  color: #fff; }

.btn-secondary:hover {
  background: transparent;
  color: #197984;
  border-color: #197984; }

.btn-secondary-elm {
  background: #197984;
  color: #fff;
  border-color: #197984; }
  .btn-secondary-elm:hover {
    color: #197984;
    background: #fff;
    border-color: #197984; }

.btn-outline-secondary-elm {
  background: transparent;
  color: #197984;
  border-color: #197984; }
  .btn-outline-secondary-elm:hover {
    background-color: #197984;
    color: #fff; }

.btn-outline-secondary-danger {
  background: transparent;
  color: #939292;
  border-color: #939292; }
  .btn-outline-secondary-danger:hover {
    background: transparent;
    color: #ff3717;
    border-color: #ff3717; }

@media (max-width: 767px) {
  .btn-sm-block {
    display: block;
    width: 100%; } }

img {
  max-width: 100%; }

@media (max-width: 991px) {
  h1, .h1 {
    font-size: 34px; } }

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 14px; } }

h1.h2, .h2.h1 {
  font-size: 30px;
  font-weight: 700; }

@media (max-width: 767px) {
  h2, .h2 {
    font-size: 16px;
    font-weight: 700; } }

a {
  transition: all 0.4s ease; }

.wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh; }

.form-control {
  border: 1px solid #afafaf;
  border-radius: 4px;
  padding: 6px 10px;
  line-height: 1; }
  .form-control:focus {
    outline: none;
    box-shadow: none; }

.white-container {
  background: #fff;
  padding: 15px; }
  @media (max-width: 991px) {
    .white-container {
      padding: 10px; } }
  @media (max-width: 767px) {
    .white-container {
      padding: 10px 12px; } }
  .white-container h2, .white-container .h2 {
    margin: 0 0 15px;
    text-transform: uppercase; }
    @media (max-width: 991px) {
      .white-container h2, .white-container .h2 {
        margin: 0 0 10px 5px; } }

.title-block {
  padding: 12px 20px; }
  @media (max-width: 767px) {
    .title-block {
      padding: 10px;
      margin: 0 -10px; } }
  .title-block h1.h2, .title-block .h2.h1 {
    margin: 0 0 2px; }
    @media (max-width: 991px) {
      .title-block h1.h2, .title-block .h2.h1 {
        font-size: 24px; } }
    @media (max-width: 767px) {
      .title-block h1.h2, .title-block .h2.h1 {
        font-size: 18px; } }

.main {
  width: 100%;
  position: relative;
  overflow: hidden; }

.container {
  max-width: 1200px; }

.social-networks {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px -5px 0; }
  .social-networks li {
    padding: 0 5px; }
  .social-networks a {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 100%;
    color: #293844;
    text-decoration: none; }
    .social-networks a:hover {
      text-decoration: none;
      background: #ff7963; }

.progress {
  border: 1px solid #939292;
  overflow: visible; }

.progress-bar {
  border-radius: 5px;
  position: relative; }

.tab-content {
  padding-top: 8px; }

.js-tab-hidden {
  display: block !important;
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important; }

.custom-control-input:checked ~ .custom-control-label::before {
  background: #ff8463;
  border-color: #ff8463; }

.footer {
  width: 100%;
  overflow: hidden;
  background: #293844;
  color: #fff;
  padding: 18px 0; }
  @media (max-width: 767px) {
    .footer {
      padding: 18px 20px; } }
  .footer h4, .footer .h4 {
    margin: 0 0 2px;
    font-weight: 700;
    text-transform: uppercase; }
  .footer p {
    margin: 0; }
  .footer a {
    text-decoration: none; }
    .footer a:hover {
      text-decoration: underline; }

.footer-list {
  position: relative;
  margin: 0 0 20px; }

.footer-list-link {
  color: #fff; }
  .footer-list-link:hover {
    color: #ff7963; }

.modal-tall .modal-dialog {
  overflow-y: initial !important; }

.modal-tall .modal-body {
  height: calc(100vh - 200px);
  overflow-y: auto; }

.header {
  position: relative;
  z-index: 99;
  padding: 3px 0 12px;
  background: #fff; }
  @media (max-width: 767px) {
    .header {
      padding: 10px 0;
      background: #fff; } }

.header-logo {
  width: 119px;
  margin: 0 12px 0 0; }
  @media (max-width: 767px) {
    .header-logo {
      width: 129px; } }
  .header-logo a {
    display: block; }
  .header-logo img {
    width: 100%;
    height: auto;
    vertical-align: top; }

.header-logo-area {
  padding: 2px 0 0 20px; }
  @media (max-width: 991px) {
    .header-logo-area {
      padding: 2px 0 0; } }
  @media (max-width: 767px) {
    .header-logo-area {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      justify-content: center; } }

.header-btns {
  margin: 0;
  padding: 5px 0 0; }
  .header-btns .header-btns-list {
    padding: 0 10px; }
    @media (max-width: 991px) {
      .header-btns .header-btns-list {
        padding: 0 5px; } }
  .header-btns .btn {
    min-width: 140px;
    text-align: center; }
    @media (max-width: 991px) {
      .header-btns .btn {
        min-width: 120px;
        padding: 5px 15px; } }

.link-underline-on-hover {
  text-decoration: none; }
  .link-underline-on-hover:hover {
    text-decoration: underline; }

.link-no-underline-on-hover {
  text-decoration: none; }
  .link-no-underline-on-hover:hover {
    text-decoration: none; }

.link-blue {
  color: #018b98;
  text-decoration: underline;
  background-color: transparent; }
  .link-blue:hover {
    color: #7acfd6;
    text-decoration: none; }

.link-gray {
  color: #909090;
  text-decoration: underline;
  background-color: transparent; }
  .link-gray:hover {
    color: #939292;
    text-decoration: none; }

body {
  min-width: 320px;
  font-size: 14px; }

.articles-slider {
  position: relative;
  margin: 0 0 5px;
  min-height: 240px; }
  @media (max-width: 767px) {
    .articles-slider {
      margin: 0 0 10px; } }
  .articles-slider .slick-list {
    padding: 0 120px 0 50px; }
    @media (max-width: 991px) {
      .articles-slider .slick-list {
        padding: 0 80px; } }
  .articles-slider .slide {
    flex: 1;
    padding: 0 10px; }
    @media (max-width: 767px) {
      .articles-slider .slide {
        padding: 0 5px; } }
  .articles-slider .slick-track {
    display: flex; }
    .articles-slider .slick-track:before, .articles-slider .slick-track:after {
      display: none; }
  .articles-slider + .btn {
    margin: 0 auto;
    max-width: 160px;
    display: block; }

.aside-block {
  font-size: 14px;
  line-height: 20px;
  padding: 15px;
  overflow: hidden;
  background: #293844; }
  @media (max-width: 991px) {
    .aside-block {
      padding: 10px; } }
  @media (max-width: 767px) {
    .aside-block {
      margin: 0 -10px;
      padding: 15px; } }

.aside-block-list {
  color: #fff;
  padding: 5px 0 0;
  overflow: hidden;
  margin: 0; }
  .aside-block-list li {
    margin: 0 0 14px;
    display: flex;
    align-items: center; }
  .aside-block-list .fa, .aside-block-list .fas {
    font-size: 24px;
    color: #ff8463;
    margin: 0 6px 0 0;
    width: 28px; }
  .aside-block-list .aside-block-list-text {
    flex-grow: 1;
    flex-basis: 0; }

.aside-block-participant {
  background: #018b98; }
  .aside-block-participant .aside-block-list .fa, .aside-block-participant .aside-block-list .fas {
    color: #fff; }
  .aside-block-participant .btn-link {
    color: #fff;
    text-decoration: underline; }
    .aside-block-participant .btn-link:hover {
      text-decoration: underline;
      color: #d9d9d9; }

.aside-block-btn-wrap {
  text-align: center;
  margin: 10px 0 23px; }
  .aside-block-btn-wrap .btn {
    min-width: 140px; }

.aside-block-map {
  font-size: 0;
  line-height: 0;
  width: 100%;
  overflow: hidden;
  margin: 22px 0 0; }

.aside-block-participation-mention {
  color: #fff; }

.block-detail {
  font-size: 14px;
  line-height: 22px;
  position: relative;
  overflow: hidden; }
  @media (max-width: 767px) {
    .block-detail {
      font-size: 13px; } }
  .block-detail h2, .block-detail .h2 {
    color: #fff;
    font-weight: 700; }
  .block-detail p {
    margin: 0 0 14px; }
  .block-detail .btn {
    padding: 5px 13px; }

.block-detail-img {
  max-width: inherit;
  width: 580px;
  position: relative;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 767px) {
    .block-detail-img {
      width: 100%;
      position: static;
      transform: none; } }

.block-detail-caption {
  color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 11px 20px 20px;
  z-index: 2; }
  @media (max-width: 991px) {
    .block-detail-caption {
      padding: 10px 10px 20px; } }
  @media (max-width: 767px) {
    .block-detail-caption {
      position: static;
      background: #2a3845; } }
  .block-detail-caption:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: -80px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(41, 56, 68, 0.95) 100%);
    content: "";
    z-index: -1; }
    @media (max-width: 767px) {
      .block-detail-caption:before {
        display: none; } }

.block-info {
  position: relative;
  overflow: hidden;
  margin: 0 0 20px; }
  @media (max-width: 767px) {
    .block-info {
      margin: 0 0 12px; } }

.block-news {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding-right: 15px; }

.collapsible-caret-container {
  cursor: pointer; }
  .collapsible-caret-container[aria-expanded="true"] .collapsible-caret-down {
    display: none; }
  .collapsible-caret-container[aria-expanded="true"] .collapsible-caret-up {
    display: initial; }

.collapsible-caret-up {
  display: none; }

.custom-modal .close {
  width: 26px;
  height: 26px;
  position: absolute;
  right: 11px;
  top: 10px;
  font-size: 24px;
  font-weight: 400;
  border-radius: 100%;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center; }

.custom-modal .modal-content {
  padding: 25px 15px 5px; }

.custom-modal h2, .custom-modal .h2 {
  font-size: 24px;
  line-height: 30px;
  color: #000;
  font-family: "Open Sans", sans-serif; }

.custom-modal .btn {
  max-width: 220px;
  margin: auto;
  padding: 5px 20px; }

.datepicker table tr td.highlighted:active,
.datepicker table tr td.highlighted.active,
.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active {
  color: #fff;
  background-color: #ff7963;
  border-color: #ff7963; }

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #939292;
  cursor: default;
  opacity: 0.4; }

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #939292;
  opacity: 0.6; }

.default-team-logo {
  height: 35px; }

.distance-selector-container {
  position: relative; }
  .distance-selector-container.distance-selector-container-search {
    margin-left: 14px;
    display: flex;
    align-items: center; }

.distance-selector-chosen-distance {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  min-width: 45px;
  border-bottom: 1px solid #197984;
  border-spacing: 10px;
  color: #fff;
  font-weight: bold;
  position: relative; }
  .distance-selector-chosen-distance:hover {
    color: #197984; }

.distance-selector-chosen-distance.distance-selector-chosen-distance-search {
  color: #197984; }
  .distance-selector-chosen-distance.distance-selector-chosen-distance-search:hover {
    color: #197984; }

.distance-selector-choices-container {
  position: absolute;
  left: -60%;
  top: 120%;
  width: 150px;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  background-color: #fff;
  border: 2px solid #197984;
  padding: 6px;
  margin-top: 2px;
  z-index: 999998; }

.distance-selector-choice {
  display: block;
  background-color: #fff;
  text-align: center;
  color: #197984;
  font-weight: 300;
  cursor: pointer; }
  .distance-selector-choice:hover {
    color: #fff;
    background-color: #197984; }

@media only screen and (max-width: 1023px) {
  .distance-selector-container.distance-selector-container-search {
    margin: 0;
    margin-top: 14px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    display: initial; }
  .distance-selector-container .distance-selector-label-search {
    display: inline; }
  .distance-selector-container .distance-selector-chosen-distance-search {
    height: 100%; }
  .distance-selector-choices-container {
    right: -10%; }
  .distance-selector-search {
    width: 100%;
    margin-top: 14px; }
  .distance-selector-container-home {
    margin-top: 10px;
    margin-bottom: 10px; } }

.entity-box-title {
  font-weight: 700;
  color: #3b3a36;
  text-transform: uppercase;
  font-size: 1rem; }

.entity-box-canceled {
  opacity: 0.5;
  background-color: #afafaf; }

.entity-box-started {
  opacity: 0.6; }

.entity-box-holidays-session {
  border: 4px solid #ff3717 !important; }

.entity-picture-round {
  border-radius: 50%;
  height: 150px;
  width: 150px; }

.entity-picture-round-extra-small {
  height: 27px;
  width: 27px; }

.entity-selector-dropdown-container .select2-results__option {
  color: #ff7963; }
  .entity-selector-dropdown-container .select2-results__option.select2-results__option--highlighted {
    color: #fff;
    background-color: #ff7963; }

.entity-selector-select2-container .select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.entity-selector-select2-container .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #ff8463;
  border-color: #ff7963;
  color: #fff; }

.famed-box {
  border: 0.1rem solid #293844 !important;
  overflow: hidden;
  text-align: center;
  padding: 10px; }
  @media (max-width: 767px) {
    .famed-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 18px; } }

@media (max-width: 767px) {
  .famed-box-text {
    margin: 0 22px 0 0; } }

.famed-box-title {
  font-family: "Roboto Slab", serif;
  font-size: 12px;
  line-height: 16px;
  display: block;
  margin: 0 0 2px;
  text-transform: uppercase; }

.famed-box-sub-title {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px; }
  @media (max-width: 767px) {
    .famed-box-sub-title {
      margin: 0; } }

.famed-box-img {
  width: 94px;
  height: 94px;
  border-radius: 100%;
  margin: 0 auto 17px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .famed-box-img {
      margin: 0 0 0 22px; } }
  .famed-box-img img {
    width: 100%;
    height: 100%; }

.filter-options {
  font-size: 14px;
  line-height: 18px;
  color: #000;
  overflow: hidden;
  text-align: left;
  padding: 15px; }
  @media (max-width: 767px) {
    .filter-options {
      padding: 0 0 5px;
      margin: 0 -10px 25px; } }
  .filter-options h2, .filter-options .h2 {
    font-size: 15px;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    margin: 0 0 15px; }
    @media (max-width: 767px) {
      .filter-options h2, .filter-options .h2 {
        font-size: 18px; } }
  .filter-options ul {
    list-style: none;
    padding: 0;
    margin: 0 -5px;
    display: flex;
    flex-flow: row wrap; }
    .filter-options ul li {
      width: 100%;
      padding: 0 5px;
      margin: 0 0 10px; }
      @media (max-width: 767px) {
        .filter-options ul li {
          width: auto; } }
    .filter-options ul .custom-control-label {
      line-height: 24px; }
  .filter-options .form-control {
    width: 100%;
    margin: 0 0 10px; }

.filter-options-link {
  font-size: 16px;
  text-decoration: underline; }

.filter-options-points-wrap {
  position: relative;
  height: 16px; }

.filter-options-points {
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  left: 0;
  top: 0;
  color: #8b8a8a; }
  .filter-options-points:nth-child(2) {
    left: 16%; }
  .filter-options-points:nth-child(3) {
    left: 32%; }
  .filter-options-points:nth-child(4) {
    left: 48%; }
  .filter-options-points:nth-child(5) {
    left: 66%; }
  .filter-options-points:nth-child(6) {
    left: auto;
    right: 0; }

.filters-search {
  font: 14px/18px "Open Sans", sans-serif;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  padding: 15px 19px;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  width: 820px; }
  @media (max-width: 991px) {
    .filters-search {
      left: auto;
      transform: none;
      width: auto;
      margin: 0 auto;
      padding: 15px;
      max-width: 748px; } }
  @media (max-width: 767px) {
    .filters-search {
      font-size: 12px;
      line-height: 16px;
      padding: 10px; } }
  .filters-search label {
    margin: 0 15px 0 0; }
    @media (max-width: 767px) {
      .filters-search label {
        display: block;
        text-align: center;
        margin: 0 0 0 -10px;
        font-size: 12px;
        max-width: 115px;
        min-width: 115px; } }
  .filters-search .form-control {
    width: 200px; }
    @media (max-width: 991px) {
      .filters-search .form-control {
        width: 165px; } }
    @media (max-width: 767px) {
      .filters-search .form-control {
        width: 100%;
        min-width: 150px;
        text-align: center; } }

.filters-search-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .filters-search-holder {
      width: auto;
      margin: 0 -10px;
      padding: 0 10px; } }
  .filters-search-holder .btn {
    margin: 0 0 0 15px; }
    @media (max-width: 767px) {
      .filters-search-holder .btn {
        margin: 0 auto;
        position: relative;
        right: -56px; } }

.filters-search-wrap {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (max-width: 767px) {
    .filters-search-wrap {
      width: 100%;
      margin: 0 0 10px; } }

.filters-search-filters {
  margin: 0;
  padding: 20px 0 5px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-flow: row wrap; }
  @media (max-width: 767px) {
    .filters-search-filters {
      padding: 0; } }

.filters-search-filters-opener {
  text-decoration: underline;
  color: #fff;
  display: inline-block; }
  @media (max-width: 767px) {
    .filters-search-filters-opener {
      font-size: 12px;
      margin: -20px 0 0 20px; } }
  .filters-search-filters-opener:hover {
    color: #ff7963; }

.filters-search-filters-slide {
  width: 100%; }

.filters-search-filters-list {
  margin: 0 -3px;
  padding: 10px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start !important; }
  @media (max-width: 991px) {
    .filters-search-filters-list {
      margin: 0 -2px; } }
  @media (max-width: 767px) {
    .filters-search-filters-list {
      padding: 18px 0 0;
      margin: 0 -4px; } }
  .filters-search-filters-list li {
    margin: 0 4px 10px;
    position: relative; }
    @media (max-width: 991px) {
      .filters-search-filters-list li {
        margin: 0 2px 10px; } }
    @media (max-width: 767px) {
      .filters-search-filters-list li {
        margin: 0 4px 10px; } }
    .filters-search-filters-list li .btn {
      border-radius: 5px;
      padding: 5px 10px;
      font-weight: 400;
      margin: 0;
      min-width: 52px; }
      @media (max-width: 991px) {
        .filters-search-filters-list li .btn {
          padding: 5px 8px; } }
      @media (max-width: 767px) {
        .filters-search-filters-list li .btn {
          font-size: 13px; } }
    .filters-search-filters-list li.active .btn,
    .filters-search-filters-list li.active .filters-search-filters-list-date-field {
      background: #ff8463;
      color: #fff; }
      .filters-search-filters-list li.active .btn::placeholder,
      .filters-search-filters-list li.active .filters-search-filters-list-date-field::placeholder {
        color: #fff; }

.filters-search-filters-link {
  text-decoration: underline;
  color: #fff; }
  .filters-search-filters-link:hover {
    color: #ff7963; }

.filters-search-filters-secondlast-item .filters-search-filters-list-slide,
.filters-search-filters-last-item .filters-search-filters-list-slide {
  left: auto;
  right: 0; }

.filters-search-filters-list-date-field {
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  line-height: 18px;
  padding: 5px;
  width: 90px;
  text-align: center;
  border-radius: 5px;
  outline: none;
  background: transparent;
  color: #ff8463;
  border: 1px solid #ff8463; }
  .filters-search-filters-list-date-field::placeholder {
    color: #ff8463;
    opacity: 1; }
  .filters-search-filters-list-date-field:hover {
    background: #ff8463;
    color: #fff; }
    .filters-search-filters-list-date-field:hover::placeholder {
      color: #fff; }

.filters-search-filters-list-slide {
  position: absolute;
  width: 240px;
  top: 100%;
  left: 0;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }

.form-newsletter {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  margin: 8px 0 40px;
  padding: 0 35px 0 0; }
  @media (max-width: 767px) {
    .form-newsletter {
      width: 220px; } }
  .form-newsletter .btn {
    padding: 4px;
    text-align: center;
    border-radius: 6px;
    height: 30px;
    width: 30px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center; }

.form-newsletter-wrap {
  width: 100%; }

.form-login {
  position: relative;
  overflow: hidden; }
  .form-login label {
    color: #197984;
    font-weight: 700;
    text-transform: uppercase; }
  .form-login .form-control {
    margin: 0 0 10px; }
  .form-login .btn {
    margin: 0 0 10px; }

.header-search {
  position: absolute;
  left: 0;
  top: 0; }

.header-search-opener {
  width: 40px;
  height: 40px;
  border: 1px solid #ff7963;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 10px;
  top: 9px;
  z-index: 1;
  font-size: 22px;
  line-height: 1;
  text-decoration: none; }
  .header-search-active .header-search-opener {
    color: #fff;
    border-color: #fff; }

.header-search-holder {
  transition: transform 0.25s ease-in-out;
  width: 80%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  transform: translateX(-100%);
  background: rgba(0, 0, 0, 0.9); }
  .header-search-active .header-search-holder {
    transform: translateX(0); }

.header-search-filter {
  overflow: hidden;
  width: 100%;
  padding: 15px 0;
  text-align: right; }
  .header-search-filter a {
    color: #fff;
    text-decoration: underline; }
    .header-search-filter a:hover {
      color: #ff7963; }

.form-control:not([readonly]):focus:focus, .form-control:not([readonly]):focus:active {
  border: 1px solid #ff3717; }

.form-control[readonly] {
  cursor: initial; }

.input-group-append > .btn {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.invisible-input {
  height: 0px;
  width: 0px;
  border: 0px; }
  .invisible-input:focus {
    outline: none; }

.jcf-range {
  display: inline-block;
  min-width: 200px;
  width: 100%;
  height: 21px;
  overflow: hidden;
  margin: 0 0 10px; }

.jcf-range .jcf-range-track {
  margin: 0 20px 0 0;
  position: relative;
  display: block; }

.jcf-range .jcf-range-wrapper {
  background: #8b8b8b;
  border-radius: 5px;
  display: block;
  margin: 8px 1px;
  height: 5px; }

.jcf-range.jcf-vertical {
  width: auto; }

.jcf-range.jcf-vertical .jcf-range-wrapper {
  margin: 0;
  width: 10px;
  height: auto;
  padding: 20px 0 0; }

.jcf-range.jcf-vertical .jcf-range-track {
  height: 180px;
  width: 10px; }

.jcf-range.jcf-vertical .jcf-range-handle {
  left: -5px;
  top: auto; }

.jcf-range .jcf-range-handle {
  position: absolute;
  background: #ff8463;
  border-radius: 19px;
  width: 20px;
  height: 20px;
  margin: -8px 0 0;
  z-index: 1;
  top: 0;
  left: 0; }
  .jcf-range .jcf-range-handle:before {
    position: absolute;
    left: -1000px;
    top: 8px;
    right: 0;
    height: 5px;
    content: '';
    border-radius: 5px;
    background: #ff8463; }

.jcf-range .jcf-range-mark {
  position: absolute;
  overflow: hidden;
  background: #000;
  width: 1px;
  height: 3px;
  top: -7px;
  margin: 0 0 0 9px; }

.jcf-range.jcf-vertical .jcf-range-mark {
  margin: 0 0 9px;
  left: 14px;
  top: auto;
  width: 3px;
  height: 1px; }

.jcf-range.jcf-focus .jcf-range-handle {
  margin: -8px 0 0 -1px; }

.jcf-range.jcf-disabled {
  background: none !important;
  opacity: 0.3; }

/* common custom form elements styles */
.jcf-disabled {
  background: #ddd !important; }

.jcf-focus,
.jcf-focus * {
  border-color: #f00 !important; }

.modal-small {
  max-width: 450px;
  width: 100%; }
  @media (max-width: 767px) {
    .modal-small {
      max-width: inherit;
      width: auto; } }
  .modal-small .modal-content {
    padding: 50px 40px; }
    @media (max-width: 767px) {
      .modal-small .modal-content {
        padding: 50px 20px; } }
    .modal-small .modal-content .modal-content-wrap {
      padding: 20px 0 0; }
    .modal-small .modal-content .modal-content-link {
      display: inline-block;
      vertical-align: top;
      text-decoration: underline;
      margin: 0 0 15px; }
      .modal-small .modal-content .modal-content-link:hover {
        text-decoration: none; }
    .modal-small .modal-content .modal-content-link-wrap {
      display: flex;
      flex-flow: row wrap; }
      @media (max-width: 767px) {
        .modal-small .modal-content .modal-content-link-wrap {
          justify-content: center; } }
      @media (max-width: 767px) {
        .modal-small .modal-content .modal-content-link-wrap a {
          margin: 0 10px 15px; } }
      .modal-small .modal-content .modal-content-link-wrap a:last-child {
        margin-left: auto; }
        @media (max-width: 767px) {
          .modal-small .modal-content .modal-content-link-wrap a:last-child {
            margin: 0 10px 15px; } }
    .modal-small .modal-content .modal-content-form-search {
      overflow: hidden;
      display: flex;
      flex-flow: row wrap;
      margin: 0 0 5px; }
      .modal-small .modal-content .modal-content-form-search input.form-control {
        width: 280px;
        margin-right: 20px; }
        @media (max-width: 767px) {
          .modal-small .modal-content .modal-content-form-search input.form-control {
            width: 100%;
            margin: 0 0 10px; } }
      .modal-small .modal-content .modal-content-form-search .btn {
        width: 147px; }
        @media (max-width: 767px) {
          .modal-small .modal-content .modal-content-form-search .btn {
            margin-left: auto; } }
    .modal-small .modal-content .modal-content-text-lg {
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 20px; }
      @media (max-width: 767px) {
        .modal-small .modal-content .modal-content-text-lg {
          font-size: 12px;
          line-height: 16px; } }
    .modal-small .modal-content .modal-content-lbl {
      font-size: 14px;
      line-height: 18px;
      margin: 0 0 15px;
      display: inline-block; }
      @media (max-width: 767px) {
        .modal-small .modal-content .modal-content-lbl {
          font-size: 12px;
          line-height: 16px;
          margin: 0 0 10px; } }
    .modal-small .modal-content .modal-content-form-textarea {
      overflow: hidden; }
      .modal-small .modal-content .modal-content-form-textarea textarea {
        height: 78px;
        margin: 0 0 15px; }
        @media (max-width: 767px) {
          .modal-small .modal-content .modal-content-form-textarea textarea {
            font-size: 12px;
            line-height: 16px; } }
      .modal-small .modal-content .modal-content-form-textarea .btn {
        width: 150px;
        margin: 0 auto; }
    .modal-small .modal-content .modal-content-filters {
      overflow: hidden;
      margin: 0 0 20px; }
      @media (max-width: 767px) {
        .modal-small .modal-content .modal-content-filters {
          margin: -30px 0 25px; } }
      .modal-small .modal-content .modal-content-filters .modal-content-filters-opener {
        text-decoration: underline; }
      .modal-small .modal-content .modal-content-filters .modal-content-filters-list {
        margin: 0 -3px;
        padding: 8px 0 0; }
        @media (max-width: 767px) {
          .modal-small .modal-content .modal-content-filters .modal-content-filters-list {
            padding: 18px 0 0; } }
        .modal-small .modal-content .modal-content-filters .modal-content-filters-list li {
          display: inline-block;
          vertical-align: top;
          margin: 0 3px 10px; }
          .modal-small .modal-content .modal-content-filters .modal-content-filters-list li .btn {
            border-radius: 5px;
            padding: 5px;
            font-weight: 400; }
            @media (max-width: 767px) {
              .modal-small .modal-content .modal-content-filters .modal-content-filters-list li .btn {
                font-size: 13px; } }
          .modal-small .modal-content .modal-content-filters .modal-content-filters-list li.active .btn {
            background: #ff8463;
            color: #fff; }
  .modal-small p {
    margin: 0; }
  .modal-small h2, .modal-small .h2 {
    text-transform: uppercase;
    margin: 0 0 20px; }
  .modal-small button.close {
    width: 26px;
    height: 26px;
    position: absolute;
    right: 11px;
    top: 10px;
    font-size: 24px;
    font-weight: 400;
    border-radius: 100%;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center; }
  .modal-small .btn {
    display: block;
    width: 100%; }
  .modal-small .fa-thumbs-up {
    width: 60px;
    font-size: 55px;
    line-height: 1;
    margin: 0 auto 20px;
    color: #ff8463; }
    @media (max-width: 767px) {
      .modal-small .fa-thumbs-up {
        font-size: 50px; } }
  .modal-small.addition {
    font-size: 12px;
    line-height: 18px;
    text-align: center; }
    .modal-small.addition .modal-content {
      padding: 25px 15px 5px; }
    .modal-small.addition h2, .modal-small.addition .h2 {
      font-size: 24px;
      line-height: 30px;
      color: #000;
      font-family: "Open Sans", sans-serif;
      text-transform: none;
      font-weight: 400;
      margin: 0; }
      @media (max-width: 767px) {
        .modal-small.addition h2, .modal-small.addition .h2 {
          font-size: 18px;
          line-height: 24px; } }
    .modal-small.addition p {
      margin: 0 0 20px; }
    .modal-small.addition .btn {
      max-width: 200px;
      margin: 0 auto 25px;
      padding: 5px 20px; }
  .modal-small.model-large {
    max-width: 780px; }
    @media (max-width: 767px) {
      .modal-small.model-large {
        max-width: inherit; } }
    .modal-small.model-large .modal-content {
      padding: 20px; }
      @media (max-width: 991px) {
        .modal-small.model-large .modal-content {
          padding: 20px 10px; } }
      @media (max-width: 767px) {
        .modal-small.model-large .modal-content {
          padding-top: 50px; } }
      .modal-small.model-large .modal-content h2, .modal-small.model-large .modal-content .h2 {
        color: #008b97;
        font-weight: 700;
        margin: 0; }
        @media (max-width: 767px) {
          .modal-small.model-large .modal-content h2, .modal-small.model-large .modal-content .h2 {
            font-size: 16px; } }

.modal {
  display: block !important;
  visibility: hidden; }
  .modal.show {
    visibility: visible; }

.main-menu {
  font: 14px/18px "Roboto Slab", serif;
  margin: 0 -10px; }
  @media (max-width: 991px) {
    .main-menu {
      margin: 0 -6px; } }
  @media (max-width: 767px) {
    .main-menu {
      font-size: 18px;
      line-height: 36px;
      margin: 0;
      padding: 60px 15px; } }

.main-menu-element {
  padding: 0 11px;
  position: relative; }
  @media (max-width: 991px) {
    .main-menu-element {
      padding: 0 6px; } }
  @media (max-width: 767px) {
    .main-menu-element {
      padding: 0;
      text-align: center; } }

.main-menu-element-first {
  padding-right: 20px; }
  @media (max-width: 991px) {
    .main-menu-element-first {
      padding-right: 14px; } }
  @media (max-width: 767px) {
    .main-menu-element-first {
      padding: 0; } }
  .main-menu-element-first:after {
    position: absolute;
    right: 4px;
    top: -5px;
    bottom: -5px;
    content: '';
    background: #000;
    width: 1px; }
    @media (max-width: 991px) {
      .main-menu-element-first:after {
        top: 0px;
        bottom: 0px; } }
    @media (max-width: 767px) {
      .main-menu-element-first:after {
        display: none; } }

.main-menu-item {
  color: #000;
  text-decoration: none; }
  @media (max-width: 767px) {
    .main-menu-item {
      color: #fff;
      text-align: center; } }
  @media (max-width: 767px) {
    .main-menu-item i {
      font-size: 30px; } }
  .main-menu-item:hover {
    color: #ff7963; }

.main-menu-divider {
  height: 0;
  overflow: hidden;
  border-top: 1px solid #fff;
  width: 80%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px; }

.main-menu-divider-label {
  color: #fff;
  text-align: center;
  font-style: italic;
  cursor: none; }

@media (max-width: 991px) {
  .member-element {
    text-align: center; } }

.member-element-title {
  font-weight: 700;
  color: #3b3a36;
  text-transform: uppercase;
  font-size: 1rem; }

.member-element-company-picture {
  height: 75px;
  max-width: 100px; }

.messages-block {
  overflow: hidden;
  margin: 0 0 20px; }
  .messages-block h2, .messages-block .h2 {
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 0 15px; }
    @media (max-width: 767px) {
      .messages-block h2, .messages-block .h2 {
        margin: 0 0 10px; } }

.messages-block-btn-row {
  overflow: hidden;
  text-align: center;
  margin: 20px 0 20px; }

.messages-block-link {
  display: inline-block;
  font-size: 14px;
  text-decoration: underline; }

.messages-block-list {
  font-size: 14px;
  line-height: 18px;
  margin: 0; }
  @media (max-width: 767px) {
    .messages-block-list {
      font-size: 12px; } }
  .messages-block-list li {
    margin: 0 0 15px; }
    .messages-block-list li:last-child {
      border: 0;
      margin: 0 0 7px; }
      .messages-block-list li:last-child ul {
        margin: 0; }
  .messages-block-list p {
    margin: 0; }
  .messages-block-list ul {
    padding-left: 60px;
    margin: 0 0 13px; }
    @media (max-width: 991px) {
      .messages-block-list ul {
        padding-left: 35px; } }
    .messages-block-list ul li {
      border: 0;
      margin: 0; }

.messages-block-list-box {
  overflow: hidden; }

.messages-block-list-title {
  font-size: 12px;
  display: block;
  color: #ff8463;
  font-weight: 400; }

.messages-block-list-link {
  overflow: hidden;
  text-align: right;
  margin: 0 0 3px; }
  .messages-block-list-link a {
    font-size: 12px;
    text-decoration: underline; }

.messages-block-input {
  min-height: 90px; }

.messages-block-list-title-modified {
  color: #018b98; }
  .messages-block-list-title-modified a {
    color: #018b98;
    text-decoration: none; }
    .messages-block-list-title-modified a:hover {
      color: #01464c;
      text-decoration: underline; }

@media (max-width: 767px) {
  .mobile-menu {
    position: absolute;
    right: 0;
    top: 10px; } }

.mobile-menu-opener {
  width: 40px;
  height: 40px;
  border: 1px solid #ff7963;
  border-radius: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  z-index: 1;
  font-size: 22px;
  line-height: 1;
  text-decoration: none; }
  @media (max-width: 767px) {
    .mobile-menu-opener {
      display: flex; } }
  .mobile-menu-active .mobile-menu-opener {
    color: #fff;
    border-color: #fff; }

@media (max-width: 767px) {
  .mobile-menu-holder {
    transition: transform 0.25s ease-in-out;
    width: 80%;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    transform: translateX(100%);
    background: rgba(0, 0, 0, 0.9); } }

.mobile-menu-active .mobile-menu-holder {
  transform: translateX(0); }

.mobile-search-form {
  font: 14px/18px "Open Sans", sans-serif;
  overflow: hidden;
  padding: 65px 10px; }
  .mobile-search-form label {
    color: #fff;
    display: block;
    text-align: center;
    margin: 0 0 6px;
    font-size: 12px; }
  .mobile-search-form .form-control {
    width: 100%;
    text-align: center; }
  .mobile-search-form .btn {
    width: 140px;
    display: block;
    margin: 0 auto; }

.mobile-search-form-wrap {
  display: block;
  width: 100%;
  margin: 0 0 11px; }

.modal-input-textarea {
  border: 1px solid #8b898a !important;
  width: 100%;
  min-height: 100px; }
  .modal-input-textarea:-moz-placeholder {
    color: #8b898a;
    opacity: 1; }
  .modal-input-textarea::-moz-placeholder {
    color: #8b898a;
    opacity: 1; }
  .modal-input-textarea:-ms-input-placeholder {
    color: #8b898a; }
  .modal-input-textarea::-webkit-input-placeholder {
    color: #8b898a; }

.motivate-carousel {
  position: relative;
  margin: 0 -8px 10px; }
  @media (max-width: 767px) {
    .motivate-carousel {
      margin: 0 -5px 10px; } }
  .motivate-carousel .mask {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 0 10px; }
  .motivate-carousel .slideset {
    width: 100% !important;
    white-space: nowrap;
    letter-spacing: -4px; }
  .motivate-carousel .slide {
    display: inline-block;
    vertical-align: top;
    letter-spacing: normal;
    white-space: normal;
    width: 16.666%;
    padding: 0 8px; }
    @media (max-width: 767px) {
      .motivate-carousel .slide {
        width: 33.333%;
        padding: 0 5px; } }
  .motivate-carousel .btn-prev,
  .motivate-carousel .btn-next,
  .motivate-carousel .pagination {
    margin: 0 5px; }
  .motivate-carousel .btn-prev,
  .motivate-carousel .btn-next {
    width: 16px;
    height: 16px;
    border: 1px solid #8b8a8a;
    border-radius: 100%;
    color: #8b8a8a;
    display: flex;
    align-items: center;
    justify-content: center; }
    .motivate-carousel .btn-prev .fas,
    .motivate-carousel .btn-next .fas {
      font-size: 11px;
      line-height: 1; }
  .motivate-carousel .pagination {
    font-size: 12px;
    line-height: 16px; }
    .motivate-carousel .pagination ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .motivate-carousel .pagination ul li {
        display: inline-block;
        vertical-align: middle; }
        .motivate-carousel .pagination ul li.active a {
          font-weight: 700; }
      .motivate-carousel .pagination ul a {
        width: 16px;
        height: 16px;
        color: #8b8a8a;
        display: block;
        text-align: center; }

.slider-controls {
  display: flex;
  align-items: center;
  justify-content: center; }

.nav-tabs {
  font: 700 14px/18px "Roboto Slab", serif;
  text-transform: uppercase;
  overflow: hidden;
  border: 0;
  margin: 0; }
  @media (max-width: 991px) {
    .nav-tabs {
      font-size: 12px; } }
  @media (max-width: 767px) {
    .nav-tabs {
      font-size: 14px; } }
  .nav-tabs .nav-item {
    padding: 0 5px;
    flex-basis: 0;
    flex-grow: 1; }
    @media (max-width: 767px) {
      .nav-tabs .nav-item {
        flex-basis: 50%;
        width: 50%;
        padding: 0px;
        height: 55px;
        margin-bottom: 0px; } }
    .nav-tabs .nav-item:first-child {
      padding-left: 0; }
      @media (max-width: 767px) {
        .nav-tabs .nav-item:first-child {
          display: block; } }
    .nav-tabs .nav-item .nav-link {
      color: #fff;
      background: #008b97;
      opacity: 0.7;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      text-decoration: none;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
      @media (max-width: 767px) {
        .nav-tabs .nav-item .nav-link {
          border-top-left-radius: 0;
          border-top-right-radius: 0; } }
      .nav-tabs .nav-item .nav-link:hover, .nav-tabs .nav-item .nav-link.active {
        color: #008b97;
        background: #fff;
        opacity: 1;
        border: 1px solid #008b97; }
    .nav-tabs .nav-item:first-child .nav-link {
      border-top-left-radius: 5px; }
    .nav-tabs .nav-item:nth-of-type(2) .nav-link {
      border-top-right-radius: 5px; }

.news-list {
  margin: 0;
  height: 285px;
  overflow: auto; }
  .news-list time {
    display: block;
    color: #8e8d8b;
    text-transform: uppercase; }
  .news-list .news-list-title {
    margin: 0 0 10px 0;
    font-size: 14px;
    font-weight: 500; }
  .news-list p {
    margin: 0; }
  .news-list a {
    color: #018b98;
    text-decoration: underline;
    background-color: transparent; }
    .news-list a:hover {
      color: #7acfd6;
      text-decoration: none; }

.news-list-item {
  position: relative;
  margin: 0 0 19px 0;
  line-height: 1.1; }
  .news-list-item:last-child {
    margin: 0; }

.news-list-announcement-container {
  display: block;
  margin-top: 50px;
  margin-bottom: 50px; }

.notification-badge-container {
  position: relative;
  padding: 2px 5px; }

.notification-badge {
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #ff566f;
  color: white;
  padding: 1px 5px;
  font-size: 10px;
  position: absolute;
  top: -5px;
  right: -5px; }
  @media (max-width: 991px) {
    .notification-badge {
      height: 15px;
      width: 15px;
      font-size: 8px;
      top: -3px;
      right: -3px; } }
  @media (max-width: 767px) {
    .notification-badge {
      height: 20px;
      width: 20px;
      font-size: 10px;
      top: -5px;
      right: -5px; } }

.notification-badge-large-number {
  right: -5px; }

@media only screen and (max-width: 1023px) {
  .notification-badge-container {
    color: #FFF; } }

.applicants-block {
  overflow: hidden;
  margin: 0 0 20px;
  font-size: 14px; }
  @media (max-width: 767px) {
    .applicants-block {
      margin: 0 -15px 12px; } }
  .applicants-block h2, .applicants-block .h2 {
    color: #008b97;
    margin: 0 0 15px;
    font-weight: 700;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .applicants-block h2, .applicants-block .h2 {
        font-size: 16px;
        margin: 0 0 10px; } }

.applicants-block-btn-wrap {
  overflow: hidden;
  text-align: center; }

.applicants-box {
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
  background: #f5f3eb;
  padding: 10px 7px 5px;
  text-align: center;
  height: 210px;
  display: flex;
  flex-direction: column; }
  @media (max-width: 767px) {
    .applicants-box {
      font-size: 12px;
      padding: 10px 5px 5px;
      flex-direction: row;
      flex-wrap: wrap; } }
  .applicants-box p {
    margin: 0; }
  .applicants-box.disabled .applicants-box-footer {
    background: #b7b6b6;
    font-size: 10px;
    line-height: 12px; }
    .applicants-box.disabled .applicants-box-footer .applicants-box-subtitle {
      margin: 0; }
    .applicants-box.disabled .applicants-box-footer input[type="checkbox"] {
      display: none; }

.applicants-box-pending {
  height: 240px; }

.applicants-box-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin: auto; }
  @media (max-width: 767px) {
    .applicants-box-img-container {
      width: 40%;
      margin: 0;
      height: auto; } }

.applicants-box-img-wrapper {
  position: relative;
  width: fit-content; }

.applicants-box-img {
  width: 75px;
  height: 75px;
  overflow: hidden;
  border-radius: 100%;
  margin: 0 auto 7px; }
  @media (max-width: 767px) {
    .applicants-box-img {
      width: 100px;
      height: 100px;
      margin: 0; } }
  .applicants-box-img img {
    width: 100%;
    height: auto;
    vertical-align: top; }

.applicants-box-img-company-logo {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 100%;
  position: absolute;
  right: -2px;
  top: 45px; }
  @media (max-width: 991px) {
    .applicants-box-img-company-logo {
      width: 26px;
      height: 26px;
      top: 50px; } }
  @media (max-width: 767px) {
    .applicants-box-img-company-logo {
      width: 40px;
      height: 40px;
      top: 65px; } }
  .applicants-box-img-company-logo img {
    border-radius: 100%;
    display: block;
    width: 36px;
    height: 36px; }
    @media (max-width: 991px) {
      .applicants-box-img-company-logo img {
        width: 26px;
        height: 26px; } }
    @media (max-width: 767px) {
      .applicants-box-img-company-logo img {
        width: 40px;
        height: 40px; } }

.applicants-box-text {
  overflow: hidden;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  @media (max-width: 767px) {
    .applicants-box-text {
      width: 60%; } }

.applicants-box-identity {
  color: #008b97;
  font-weight: bold;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.applicants-box-fake {
  background: #ff8463; }
  .applicants-box-fake .applicants-box-identity {
    color: #fff; }

.applicants-box-partner {
  background: #7acfd6; }

.applicants-box-footer {
  background: #008b97;
  margin: 5px -7px -5px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  height: 30px;
  padding: 0 5px; }
  @media (max-width: 767px) {
    .applicants-box-footer {
      font-size: 10px; } }

.applicants-box-subtitle {
  margin-right: 12px; }
  @media (max-width: 767px) {
    .applicants-box-subtitle {
      margin-right: 7px; } }

.applicants-box-validation-actions {
  width: 100%;
  margin-top: 10px; }

@media (max-width: 767px) {
  .applicants-box-validation-form {
    width: 80%;
    margin: auto; } }

.applicants-box-not-showed-up {
  opacity: 0.7; }

.applicants-box-not-showed-up-clicked {
  opacity: 1;
  border: 3px solid #018b98; }

.progress-block {
  overflow: hidden;
  max-width: 420px;
  margin-bottom: 48px !important; }
  @media (max-width: 767px) {
    .progress-block {
      background: #fff;
      max-width: inherit;
      padding: 0 10px 25px;
      margin: 0 !important; } }
  .progress-block h2, .progress-block .h2 {
    font-weight: 700; }

.progress-block-textbox {
  margin: 0 0 12px; }

.progress-block-img {
  width: 47px;
  margin: 0 15px 0 0; }
  .progress-block-img img {
    width: 100%;
    height: auto;
    vertical-align: top; }

.progress-block-txt {
  flex-grow: 1;
  flex-basis: 0; }

.progress-block-value {
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  color: #ff8463;
  position: absolute;
  top: 100%;
  right: 0; }
  @media (max-width: 767px) {
    .progress-block-value {
      font-size: 10px; } }

.progress-block-wrap {
  overflow: hidden; }

.progress-block-points-wrap {
  position: relative;
  height: 16px; }

.progress-block-points {
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  left: 0;
  top: 0;
  color: #8b8a8a; }
  @media (max-width: 767px) {
    .progress-block-points {
      font-size: 10px;
      line-height: 20px; } }
  .progress-block-points:nth-child(2) {
    left: 23%; }
  .progress-block-points:nth-child(3) {
    left: 47%; }
  .progress-block-points:nth-child(4) {
    left: 72%; }
  .progress-block-points:nth-child(5) {
    left: auto;
    right: 0; }

.progress-block-link {
  display: inline-block;
  margin-top: 15px;
  text-decoration: underline; }
  @media (max-width: 767px) {
    .progress-block-link {
      margin-top: 10px; } }
  .progress-block-link:hover {
    text-decoration: none; }

.search-form {
  font: 14px/18px "Open Sans", sans-serif;
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  padding: 15px 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  left: 50%;
  transform: translateX(-50%); }
  @media (max-width: 991px) {
    .search-form {
      left: auto;
      transform: none;
      width: auto;
      margin: 0 auto;
      flex-wrap: wrap;
      justify-content: space-evenly; } }
  @media (max-width: 767px) {
    .search-form {
      padding: 10px 15px; } }
  .search-form label {
    margin: 0 8px 0 8px; }
    @media (max-width: 767px) {
      .search-form label {
        display: block;
        text-align: center;
        margin: 0 0 6px;
        font-size: 12px; }
        .search-form label.search-form-label-inline-block-tablet {
          display: inline-block; } }
  .search-form .form-control {
    width: 280px; }
    @media (max-width: 991px) {
      .search-form .form-control {
        width: 200px; } }
    @media (max-width: 767px) {
      .search-form .form-control {
        width: 100%;
        text-align: center; } }
  .search-form .select2.select2-container.select2-container--default {
    width: 280px !important; }
    @media (max-width: 991px) {
      .search-form .select2.select2-container.select2-container--default {
        width: 200px !important; } }
    @media (max-width: 767px) {
      .search-form .select2.select2-container.select2-container--default {
        width: 100% !important;
        text-align: center !important; } }
    .search-form .select2.select2-container.select2-container--default .select2-search.select2-search--inline {
      width: 280px; }
      @media (max-width: 991px) {
        .search-form .select2.select2-container.select2-container--default .select2-search.select2-search--inline {
          width: 200px !important; } }
      @media (max-width: 767px) {
        .search-form .select2.select2-container.select2-container--default .select2-search.select2-search--inline {
          width: 100% !important;
          text-align: center !important; } }
  .search-form .btn {
    margin: 0 0 0 15px; }
    @media (max-width: 767px) {
      .search-form .btn {
        margin: 15px 0 0 0;
        width: 100%; } }

.search-form-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.search-form-wrap {
  display: flex;
  flex-direction: row;
  align-items: center; }
  @media (max-width: 767px) {
    .search-form-wrap {
      display: block;
      width: 100%;
      margin: 0 0 11px; } }

.search-form-wrap-home-sport .select2-container .select2-selection--multiple {
  padding: 6px 0px; }

.search-form-subpage {
  overflow: hidden; }
  @media (max-width: 991px) {
    .search-form-subpage {
      padding-bottom: 10px; } }
  @media (max-width: 767px) {
    .search-form-subpage {
      background: #fff; } }
  .search-form-subpage h2, .search-form-subpage .h2 {
    margin: 0 0 12px; }

.search-form-subpage-link {
  text-decoration: underline; }
  .search-form-subpage-link:hover {
    text-decoration: none; }

.search-results {
  overflow: hidden;
  margin: -20px 0 0; }
  @media (max-width: 767px) {
    .search-results {
      margin-top: -12px; } }
  .search-results h2, .search-results .h2 {
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 0 15px; }
    @media (max-width: 991px) {
      .search-results h2, .search-results .h2 {
        margin: 0 0 10px; } }

.search-results-map {
  width: 100%;
  height: 965px;
  overflow: hidden; }
  @media (max-width: 767px) {
    .search-results-map {
      height: 160px;
      width: auto;
      margin: 0 -5px 12px; } }
  .search-results-map iframe {
    width: 100%;
    height: 100%; }

.search-results-slider {
  margin: 0 -10px 10px;
  display: flex;
  flex-flow: row wrap; }
  @media (max-width: 991px) {
    .search-results-slider {
      margin: 0 -5px 10px; } }
  @media (min-width: 768px) {
    .search-results-slider {
      overflow: auto;
      height: 965px; } }
  @media (max-width: 767px) {
    .search-results-slider .slick-list {
      padding: 0 80px; } }
  .search-results-slider .slick-track {
    display: flex; }
    @media (min-width: 768px) {
      .search-results-slider .slick-track {
        width: 100% !important;
        transform: none !important;
        flex-flow: row wrap; } }
  .search-results-slider .slick-slide {
    padding: 0 3px;
    float: none;
    height: auto; }
    @media (min-width: 768px) {
      .search-results-slider .slick-slide {
        padding: 0 5px;
        width: 50% !important;
        margin: 0 0 10px; } }
    @media (min-width: 992px) {
      .search-results-slider .slick-slide {
        margin: 0 0 20px;
        padding: 0 10px; } }
    @media (min-width: 768px) {
      .search-results-slider .slick-slide.slick-cloned {
        display: none !important; } }
  @media (min-width: 768px) {
    .search-results-slider .slick-arrow {
      display: none !important; } }

.select2-container .select2-selection--multiple,
.select2-container .select2-selection--single {
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 1.25; }

.select2-container .select2-search {
  width: 100%; }

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0px;
  width: 100% !important; }
  .select2-container .select2-search--inline .select2-search__field::placeholder {
    color: #b7b6b6;
    opacity: 1; }
  @media (max-width: 767px) {
    .select2-container .select2-search--inline .select2-search__field {
      text-align: center; } }

.select2-container .select2-selection--multiple .select2-selection__choice {
  margin-top: 0px; }

.select2-container .select2-selection--single .select2-selection__arrow {
  height: 100%; }

.select2-container .select2-selection--single .select2-selection__rendered {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px; }

.select2-container .select2-selection--single .select2-selection__clear {
  margin-right: 10px;
  font-size: 20px; }

.session-article {
  position: relative;
  overflow: hidden;
  height: 100%;
  background: #f2f2f2;
  cursor: pointer;
  display: block;
  text-decoration: none; }
  @media (max-width: 767px) {
    .session-article {
      font-size: 10px; } }
  .slick-active .session-article:hover {
    border: 3px solid #197984;
    background-color: transparent;
    color: #197984; }
    .slick-active .session-article:hover .session-article-title {
      text-decoration: underline; }

.session-article-image-container {
  width: 100%;
  height: 110px;
  position: relative; }
  @media (max-width: 767px) {
    .session-article-image-container {
      height: 200px; } }
  @media (max-width: 991px) {
    .session-article-image-container {
      height: 130px; } }

.session-article-sport-slider-paris-logo,
.session-article-sport-slider-company-logo {
  position: absolute;
  bottom: 5px;
  right: 5px;
  max-width: 45px;
  max-height: 45px; }

.session-article-sport-slider-company-logo {
  border-radius: 100%; }

.session-article-tag-cyan,
.session-article-tag-orange {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  text-transform: uppercase;
  padding: 4px 7px;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center; }
  .session-article-tag-cyan .far,
  .session-article-tag-orange .far {
    font-size: 22px;
    line-height: 1;
    margin: 0 10px 0 0; }
    @media (max-width: 767px) {
      .session-article-tag-cyan .far,
      .session-article-tag-orange .far {
        font-size: 18px; } }

.session-article-tag-cyan {
  background: #008b97; }

.session-article-tag-orange {
  background: #ff7963; }

.session-article-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.session-article-text-holder {
  padding: 10px 8px 7px;
  color: #000; }

.session-article-list {
  margin: 0; }

.session-article-title {
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 700;
  display: block;
  color: #197984;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .session-article-title {
      margin: 0 0 4px; } }
  .session-article-title:hover {
    color: #197984; }

.session-article-item {
  position: relative;
  padding: 2px 0 2px 20px;
  margin: 0 0 1px; }
  .session-article-item .fas {
    font-size: 16px;
    line-height: 20px;
    color: #008b97;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%); }
    @media (max-width: 767px) {
      .session-article-item .fas {
        font-size: 14px;
        top: 0;
        transform: none; } }

.session-article-link {
  text-decoration: underline; }
  .session-article-link:hover {
    text-decoration: none; }

.session-block {
  overflow: hidden;
  margin: 0 0 20px;
  font-size: 14px; }
  @media (max-width: 767px) {
    .session-block {
      margin: 0 -10px 12px; } }

.session-block-sub-title {
  font-size: 12px;
  line-height: 16px;
  font-family: "Roboto Slab", serif;
  display: block;
  color: #ff7963; }

.session-block-list {
  font-size: 14px;
  line-height: 18px;
  overflow: hidden;
  margin: 0 0 7px;
  display: flex;
  flex-flow: row wrap; }
  .session-block-list .session-block-list-box {
    flex-grow: 1;
    flex-basis: 0; }
  .session-block-list li {
    width: 50%;
    display: flex;
    align-items: center;
    margin: 0 0 11px; }
    @media (max-width: 991px) {
      .session-block-list li {
        width: 100%; } }
    .session-block-list li:first-child {
      width: 100%; }
    .session-block-list li p {
      margin: 0; }

.session-block-info-list {
  font-size: 12px; }

.session-block-organization-picture {
  max-height: 75px; }

.session-cover-container {
  overflow: hidden;
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
  max-height: 400px;
  display: flex;
  align-items: center; }
  @media (max-width: 991px) {
    .session-cover-container {
      max-height: 250px; } }
  @media (max-width: 767px) {
    .session-cover-container {
      max-height: 150px; } }

.session-cover-container-not-available .session-cover-image {
  -webkit-filter: grayscale(50%);
  -moz-filter: grayscale(50%);
  -o-filter: grayscale(50%);
  -ms-filter: grayscale(50%);
  filter: grayscale(50%); }

.session-cover-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  opacity: 1;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 30px;
  width: max-content; }
  @media (max-width: 767px) {
    .session-cover-info {
      font-size: 1.2rem; } }

.session-cover-paris-image,
.session-cover-company-logo {
  position: absolute;
  width: 75px;
  height: 75px;
  z-index: 10;
  bottom: 20px;
  right: 20px; }
  @media (max-width: 991px) {
    .session-cover-paris-image,
    .session-cover-company-logo {
      width: 50px;
      height: 50px;
      bottom: 10px;
      right: 10px; } }
  @media (max-width: 767px) {
    .session-cover-paris-image,
    .session-cover-company-logo {
      width: 35px;
      height: 35px; } }

.session-cover-company-logo {
  border-radius: 100%; }

.session-info-box {
  position: relative;
  overflow: hidden;
  margin: 0 0 20px; }
  @media (max-width: 767px) {
    .session-info-box {
      margin: 0 -22px 15px;
      background: #fff; } }

.simple-entity-box-picture-container {
  width: 30%;
  height: 75px;
  text-align: center; }
  .simple-entity-box-picture-container img {
    max-height: 100%; }

.simple-entity-box-info-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.slick-slide {
  opacity: 0.5; }

.slick-active {
  opacity: 1; }

/* Slick slider styles */
.slick-slider {
  position: relative;
  display: block; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  font-size: 20px;
  line-height: 24px;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  text-decoration: none; }
  .slick-arrow:hover {
    color: #ff7963;
    text-decoration: none; }
  .slick-arrow.slick-next {
    left: auto;
    right: 0; }

.sport-picture-container {
  position: relative;
  width: fit-content;
  padding: 0 10px; }

.sport-picture {
  width: 100%;
  height: 80%;
  min-height: 90px;
  min-width: 240px; }

.sport-picture-container-extra-infos .sport-picture {
  -webkit-filter: grayscale(50%);
  -moz-filter: grayscale(50%);
  -o-filter: grayscale(50%);
  -ms-filter: grayscale(50%);
  filter: grayscale(50%); }

.sport-picture-info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  opacity: 1;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 5px;
  width: max-content; }

.sport-picture-paris-logo,
.sport-picture-organization-logo {
  position: absolute;
  bottom: 10px;
  right: 15px;
  max-width: 35px;
  max-height: 35px; }

.sport-picture-organization-logo {
  border-radius: 100%; }

.subscription-element-container-expired,
.subscription-element-container-consumed {
  opacity: 0.6; }

.tab-section-link-container {
  position: absolute;
  right: 0px;
  top: -5px;
  font-style: italic;
  text-align: right;
  color: #8b8a8a;
  text-decoration: underline; }
  .tab-section-link-container:hover {
    text-decoration: underline;
    color: #656464; }

.tab-section-link-container a {
  color: #8b8a8a;
  text-decoration: underline; }
  .tab-section-link-container a:hover {
    text-decoration: underline;
    color: #656464; }

.tab-section-team-picture {
  max-height: 50px; }

.tab-section .tab-section-title {
  color: #008b97;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }
  @media (max-width: 767px) {
    .tab-section .tab-section-title {
      margin-top: 3rem; } }

.theme-cyan h2, .theme-cyan .h2,
.theme-cyan .session-article-title,
.theme-cyan .session-article-item .fas,
.theme-cyan .progress-block-value,
.theme-cyan .progress-block-link {
  color: #008b97; }

.theme-cyan .progress-bar {
  background: #008b97; }

.theme-orange h2, .theme-orange .h2,
.theme-orange .session-article-title,
.theme-orange .session-article-item .fas,
.theme-orange .progress-block-value,
.theme-orange .progress-block-link {
  color: #ff7963; }

.theme-orange .progress-bar {
  background: #ff7963; }

.user-connected-badge {
  align-content: space-between;
  align-items: center;
  font-size: 14px; }
  .user-connected-badge a {
    font-size: 14px;
    text-decoration: none;
    color: #000; }
    .user-connected-badge a:hover {
      color: #ff7963;
      background-color: transparent; }
  .user-connected-badge i {
    font-size: 26px; }
    @media (max-width: 991px) {
      .user-connected-badge i {
        font-size: 20px; } }
  .user-connected-badge .dropdown-header,
  .user-connected-badge .dropdown-divider {
    cursor: default; }

.user-connected-badge-box {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 25px 0 0; }
  @media (max-width: 767px) {
    .user-connected-badge-box {
      margin: 0 0 20px 0;
      padding-top: 5px;
      justify-content: center;
      flex-direction: column; } }

.user-connected-badge-imgs-container-invitation-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  margin: 10px 10px 10px 0; }

.user-connected-badge-identity {
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%; }

.user-connected-badge-imgs-container {
  width: 60px;
  position: relative;
  margin: 0 10px 0 0; }
  @media (max-width: 991px) {
    .user-connected-badge-imgs-container {
      margin: 0;
      width: 50px; } }

.user-connected-badge-imgs-container-email {
  margin: 0; }
  .user-connected-badge-imgs-container-email .user-connected-badge-img {
    margin: auto; }

.user-connected-badge-img {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100%; }
  @media (max-width: 991px) {
    .user-connected-badge-img {
      width: 40px;
      height: 40px; } }
  .user-connected-badge-img img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: block; }
    @media (max-width: 991px) {
      .user-connected-badge-img img {
        width: 40px;
        height: 40px; } }

.user-connected-badge-company-logo {
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 100%;
  position: absolute;
  right: -2px;
  top: 24px; }
  @media (max-width: 991px) {
    .user-connected-badge-company-logo {
      width: 26px;
      height: 26px; } }
  .user-connected-badge-company-logo img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: block; }
    @media (max-width: 991px) {
      .user-connected-badge-company-logo img {
        width: 26px;
        height: 26px; } }

.user-connected-badge-info {
  flex-grow: 1;
  text-align: center;
  font-family: "Roboto Slab", serif;
  padding-right: 25px; }
  @media (max-width: 991px) {
    .user-connected-badge-info {
      padding-right: 10px; } }

.user-connected-badge-title {
  line-height: 20px;
  color: #3b3a36;
  display: block;
  margin: 0 0 2px;
  font-weight: 500; }
  @media (max-width: 991px) {
    .user-connected-badge-title {
      font-size: 12px;
      line-height: 18px; } }

.user-connected-badge-dropdown-opener {
  color: #3b3a36;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px; }
  @media (max-width: 991px) {
    .user-connected-badge-dropdown-opener {
      font-size: 10px; } }
  .user-connected-badge-dropdown-opener:hover {
    text-decoration: none; }

.user-connected-badge-greeting {
  margin-top: 15px;
  color: #939292; }

.text-orange-principal-dark {
  color: #ff3717; }

.text-orange-principal {
  color: #ff7963; }

.text-orange-principal-background {
  background-color: #ff7963;
  color: #fff; }

.text-blue-alt {
  color: #018b98; }

.text-white {
  color: #fff; }

.text-white-background {
  background-color: #fff; }

.text-gray {
  color: #8b8a8a; }

.text-nobel-background {
  background-color: #b7b6b6; }

.link-white-underline {
  color: #fff;
  text-decoration: underline; }
  .link-white-underline:hover {
    text-decoration: underline;
    color: #d9d9d9; }

.link-gray-underline {
  color: #8b8a8a;
  text-decoration: underline; }
  .link-gray-underline:hover {
    text-decoration: underline;
    color: #656464; }

.link-black-underline {
  color: #000;
  text-decoration: underline; }
  .link-black-underline:hover {
    text-decoration: underline;
    color: black; }

.link-orange-principal-underline {
  color: #ff7963;
  text-decoration: underline; }
  .link-orange-principal-underline:hover {
    text-decoration: underline;
    color: #ff3717; }

.link-springwood-underline {
  color: #f5f3eb;
  text-decoration: underline; }
  .link-springwood-underline:hover {
    text-decoration: underline;
    color: #dcd4b8; }

.link-blue-alt {
  color: #018b98;
  text-decoration: none; }
  .link-blue-alt:hover {
    color: #01464c;
    text-decoration: underline; }

.link-blue-alt-underline {
  color: #018b98;
  text-decoration: underline; }
  .link-blue-alt-underline:hover {
    text-decoration: underline;
    color: #01464c; }

.link-white {
  color: #fff;
  text-decoration: none; }
  .link-white:hover {
    color: #d9d9d9;
    text-decoration: underline; }

.link-darkgray {
  color: #939292;
  text-decoration: none; }
  .link-darkgray:hover {
    color: #6d6c6c;
    text-decoration: underline; }

.text-underlined {
  text-decoration: underline; }

.text-lowercase {
  text-transform: lowercase; }

.text-underlined {
  text-decoration: underline; }

.text-regular {
  font-size: 14px; }

.text-extra-small {
  font-size: 0.7rem; }

.text-small {
  font-size: 0.875rem; }

.text-base {
  font-size: 1rem; }

.text-lg {
  font-size: 1.25rem; }

.text-font-weight-normal {
  font-weight: normal; }

.text-crossed {
  text-decoration: line-through; }

.border-1 {
  border-width: 0.25rem !important; }

.border-elm {
  border-color: #197984 !important; }

.border-dark-gray {
  border-color: #939292 !important; }

.max-h-150 {
  max-height: 150px; }

.max-h-350 {
  max-height: 350px; }

.max-w-300 {
  max-width: 300px; }

.w-70 {
  width: 70%; }

.cursor-pointer {
  cursor: pointer; }

.overflow-x-scroll {
  overflow-x: scroll; }

.w-min-250 {
  min-width: 250px; }

.opacity-60 {
  opacity: 0.6; }

.text-opacity-70 {
  opacity: 0.7; }

.font-style-normal {
  font-style: normal; }

.video-info-box {
  margin: 0 0 20px; }

.video-box-container {
  width: 80%;
  margin: auto; }
  @media (max-width: 767px) {
    .video-box-container {
      width: 100%; } }

.visual {
  font: 24px/30px "Roboto Slab", serif;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  z-index: 2;
  color: #fff;
  padding: 60px 0;
  text-align: center;
  margin: 0 0 20px;
  background-image: url("../../../../images/background_top.jpg"); }
  @media (max-width: 991px) {
    .visual {
      font-size: 18px;
      line-height: 24px; } }
  @media (max-width: 767px) {
    .visual {
      font-size: 16px;
      padding: 40px 0 25px;
      margin: 0 0 12px; } }
  .visual:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 40%, rgba(0, 0, 0, 0) 100%); }
    @media (max-width: 767px) {
      .visual:before {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 40%, rgba(0, 0, 0, 0) 100%); } }
  .visual .container {
    position: relative;
    z-index: 5; }
  .visual h1, .visual .h1 {
    line-height: 1.0455;
    color: #fff;
    margin: 0 0 10px; }
    @media (max-width: 767px) {
      .visual h1, .visual .h1 {
        font-size: 16px;
        line-height: 24px;
        margin: 0; } }
  .visual .visual-text {
    margin: 0 0 30px; }
    @media (max-width: 767px) {
      .visual .visual-text {
        font-weight: 400 !important; } }

.header {
  position: relative;
  z-index: 99;
  padding: 3px 0 12px;
  background: #fff; }
  @media (max-width: 767px) {
    .header {
      padding: 10px 0;
      background: #fff; } }

.header-logo {
  width: 119px;
  margin: 0 12px 0 0; }
  @media (max-width: 767px) {
    .header-logo {
      width: 129px; } }
  .header-logo a {
    display: block; }
  .header-logo img {
    width: 100%;
    height: auto;
    vertical-align: top; }

.header-logo-area {
  padding: 2px 0 0 20px; }
  @media (max-width: 991px) {
    .header-logo-area {
      padding: 2px 0 0; } }
  @media (max-width: 767px) {
    .header-logo-area {
      width: 100%;
      margin: 0 auto;
      padding: 0;
      justify-content: center; } }

.header-btns {
  margin: 0;
  padding: 5px 0 0; }
  .header-btns .header-btns-list {
    padding: 0 10px; }
    @media (max-width: 991px) {
      .header-btns .header-btns-list {
        padding: 0 5px; } }
  .header-btns .btn {
    min-width: 140px;
    text-align: center; }
    @media (max-width: 991px) {
      .header-btns .btn {
        min-width: 120px;
        padding: 5px 15px; } }

.footer {
  width: 100%;
  overflow: hidden;
  background: #293844;
  color: #fff;
  padding: 18px 0; }
  @media (max-width: 767px) {
    .footer {
      padding: 18px 20px; } }
  .footer h4, .footer .h4 {
    margin: 0 0 2px;
    font-weight: 700;
    text-transform: uppercase; }
  .footer p {
    margin: 0; }
  .footer a {
    text-decoration: none; }
    .footer a:hover {
      text-decoration: underline; }

.footer-list {
  position: relative;
  margin: 0 0 20px; }

.footer-list-link {
  color: #fff; }
  .footer-list-link:hover {
    color: #ff7963; }

img {
  max-width: 100%; }

@media (max-width: 991px) {
  h1, .h1 {
    font-size: 34px; } }

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 14px; } }

h1.h2, .h2.h1 {
  font-size: 30px;
  font-weight: 700; }

@media (max-width: 767px) {
  h2, .h2 {
    font-size: 16px;
    font-weight: 700; } }

a {
  transition: all 0.4s ease; }

.wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh; }

.form-control {
  border: 1px solid #afafaf;
  border-radius: 4px;
  padding: 6px 10px;
  line-height: 1; }
  .form-control:focus {
    outline: none;
    box-shadow: none; }

.white-container {
  background: #fff;
  padding: 15px; }
  @media (max-width: 991px) {
    .white-container {
      padding: 10px; } }
  @media (max-width: 767px) {
    .white-container {
      padding: 10px 12px; } }
  .white-container h2, .white-container .h2 {
    margin: 0 0 15px;
    text-transform: uppercase; }
    @media (max-width: 991px) {
      .white-container h2, .white-container .h2 {
        margin: 0 0 10px 5px; } }

.title-block {
  padding: 12px 20px; }
  @media (max-width: 767px) {
    .title-block {
      padding: 10px;
      margin: 0 -10px; } }
  .title-block h1.h2, .title-block .h2.h1 {
    margin: 0 0 2px; }
    @media (max-width: 991px) {
      .title-block h1.h2, .title-block .h2.h1 {
        font-size: 24px; } }
    @media (max-width: 767px) {
      .title-block h1.h2, .title-block .h2.h1 {
        font-size: 18px; } }

.main {
  width: 100%;
  position: relative;
  overflow: hidden; }

.container {
  max-width: 1200px; }

.social-networks {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px -5px 0; }
  .social-networks li {
    padding: 0 5px; }
  .social-networks a {
    width: 36px;
    height: 36px;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 100%;
    color: #293844;
    text-decoration: none; }
    .social-networks a:hover {
      text-decoration: none;
      background: #ff7963; }

.progress {
  border: 1px solid #939292;
  overflow: visible; }

.progress-bar {
  border-radius: 5px;
  position: relative; }

.tab-content {
  padding-top: 8px; }

.js-tab-hidden {
  display: block !important;
  left: -9999px !important;
  position: absolute !important;
  top: -9999px !important; }

.custom-control-input:checked ~ .custom-control-label::before {
  background: #ff8463;
  border-color: #ff8463; }

body {
  min-width: 320px;
  font-size: 12px; }
