.custom-modal {
    .close {
        width: 26px;
        height: 26px;
        position: absolute;
        right: 11px;
        top: 10px;
        font-size: 24px;
        font-weight: 400;
        border-radius: 100%;
        border: 1px solid $black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-content {
        padding: 25px 15px 5px;
    }

    h2 {
        font-size: 24px;
        line-height: 30px;
        color: $black;
        font-family: $open-sans;
    }

    .btn {
        max-width: 220px;
        margin: auto;
        padding: 5px 20px;
    }
}
