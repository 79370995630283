.modal-small {
    max-width: 450px;
    width: 100%;

    @include media('<tablet') {
        max-width: inherit;
        width: auto;
    }

    .modal-content {
        padding: 50px 40px;

        @include media('<tablet') {
            padding: 50px 20px;
        }

        .modal-content-wrap {
            padding: 20px 0 0;
        }

        .modal-content-link {
            @include alignment;

            text-decoration: underline;
            margin: 0 0 15px;

            &:hover {
                text-decoration: none;
            }
        }

        .modal-content-link-wrap {
            display: flex;
            flex-flow: row wrap;

            @include media('<tablet') {
                justify-content: center;
            }

            a {
                @include media('<tablet') {
                    margin: 0 10px 15px;
                }

                &:last-child {
                    margin-left: auto;

                    @include media('<tablet') {
                        margin: 0 10px 15px;
                    }
                }
            }
        }

        .modal-content-form-search {
            overflow: hidden;
            display: flex;
            flex-flow: row wrap;
            margin: 0 0 5px;

            input.form-control {
                width: 280px;
                margin-right: 20px;

                @include media('<tablet') {
                    width: 100%;
                    margin: 0 0 10px;
                }
            }

            .btn {
                width: 147px;

                @include media('<tablet') {
                    margin-left: auto;
                }
            }
        }

        .modal-content-text-lg {
            font-size: 14px;
            line-height: 18px;
            margin: 0 0 20px;

            @include media('<tablet') {
                font-size: 12px;
                line-height: 16px;
            }
        }

        .modal-content-lbl {
            font-size: 14px;
            line-height: 18px;
            margin: 0 0 15px;
            display: inline-block;

            @include media('<tablet') {
                font-size: 12px;
                line-height: 16px;
                margin: 0 0 10px;
            }
        }

        .modal-content-form-textarea {
            overflow: hidden;

            textarea {
                height: 78px;
                margin: 0 0 15px;

                @include media('<tablet') {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .btn {
                width: 150px;
                margin: 0 auto;
            }
        }

        .modal-content-filters {
            overflow: hidden;
            margin: 0 0 20px;

            @include media('<tablet') {
                margin: -30px 0 25px;
            }

            .modal-content-filters-opener {
                text-decoration: underline;
            }

            .modal-content-filters-list {
                margin: 0 -3px;
                padding: 8px 0 0;

                @include media('<tablet') {
                    padding: 18px 0 0;
                }

                li {
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 3px 10px;

                    .btn {
                        border-radius: 5px;
                        padding: 5px;
                        font-weight: 400;

                        @include media('<tablet') {
                            font-size: 13px;
                        }
                    }

                    &.active {
                        .btn {
                            background: $orangeSecondary;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    p {
        margin: 0;
    }

    h2 {
        text-transform: uppercase;
        margin: 0 0 20px;
    }

    button.close {
        width: 26px;
        height: 26px;
        position: absolute;
        right: 11px;
        top: 10px;
        font-size: 24px;
        font-weight: 400;
        border-radius: 100%;
        border: 1px solid $black;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        display: block;
        width: 100%;
    }

    .fa-thumbs-up {
        width: 60px;
        font-size: 55px;
        line-height: 1;
        margin: 0 auto 20px;
        color: $orangeSecondary;

        @include media('<tablet') {
           font-size: 50px;
        }
    }

    &.addition {
        font-size: 12px;
        line-height: 18px;
        text-align: center;

        .modal-content {
            padding: 25px 15px 5px;
        }

        h2 {
            font-size: 24px;
            line-height: 30px;
            color: $black;
            font-family: $open-sans;
            text-transform: none;
            font-weight: 400;
            margin: 0;

            @include media('<tablet') {
                font-size: 18px;
                line-height: 24px;
            }
        }

        p {
            margin: 0 0 20px;
        }

        .btn {
            max-width: 200px;
            margin: 0 auto 25px;
            padding: 5px 20px;
        }
    }

    &.model-large {
        max-width: 780px;

        @include media('<tablet') {
            max-width: inherit;
        }

        .modal-content {
            padding: 20px;

            @include media('<desktop') {
                padding: 20px 10px;
            }

            @include media('<tablet') {
                padding-top: 50px;
            }

            h2 {
                color: $lagoon;
                font-weight: 700;
                margin: 0;

                @include media('<tablet') {
                    font-size: 16px;
                }
            }
        }
    }
}

.modal {
    display: block !important;
    visibility: hidden;

    &.show {
        visibility: visible;
    }
}
