// hide text
@mixin hide-text {
    overflow: hidden;
    text-indent: -9999px;
}

@mixin full-width-img {
    width: 100%;
    height: auto;
    vertical-align: top;
}

@mixin full-width-and-height-img {
    width: 100%;
    height: 100%;
}

// alignment text
@mixin alignment($value1: inline-block, $value2: top) {
    display: $value1;
    vertical-align: $value2;
}

@mixin font-size($value1, $value2: 1.25) {
    font-size: $value1;
    line-height: $value2;
}

@mixin size($width, $height: $width) {
    width: $width;
    height: $height;
}

@mixin square($value1) {
    width: $value1;
    height: $value1;
}

@mixin position-zero($value: 0) {
    top: $value;
    left: $value;
    right: $value;
    bottom: $value;
}

@mixin rgba-color($hexcolor, $opacity) {
    color: rgba($hexcolor, $opacity);
}

@mixin rgba-background($hexcolor, $opacity) {
    background-color: rgba($hexcolor, $opacity);
}

// custom font mixin
@mixin fontface ($fontfamily, $filename, $weight, $style) {
    font-family: $fontfamily;
    //src: url('../fonts/#{$filename}.eot');/* IE 11 */
    src:
        url('../fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
        url('../fonts/#{$filename}.woff2') format('woff2'),/* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/#{$filename}.woff') format('woff'),/* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
        url('../fonts/#{$filename}.svg') format('svg'),
        url('../fonts/#{$filename}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
}

// Animation
@mixin animation($value) {
    -webkit-transition:$value;
    transition:$value;
}

@mixin transform ($deg) {
    transform: $deg;
    -moz-transform: $deg;
    -ms-transform: $deg;
    -o-transform: $deg;
    -webkit-transform: $deg;
}

// some CSS3 style
@mixin css3($property, $value) {
    @each $prefix in -webkit-, -moz-, -ms-, -o-, '' {
        #{$prefix}#{$property}: $value;
    }
}

// placeholder
@mixin placeholder($color: $placeholder-color) {
    &:-moz-placeholder {
        color: $color; opacity: 1;
    }

    &::-moz-placeholder {
        color: $color; opacity: 1;
    }

    &:-ms-input-placeholder {
      color: $color;
    }

    &::-webkit-input-placeholder {
      color: $color;
    }
}

// centeralign
@mixin centeralign($width) {
    left: 50%;
    display: block;
    width: $width;
    position: relative;
    margin-left: -$width/2;
}

// bg-stretch
@mixin bg-stretch {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

// reset for before and after
@mixin pseudo-elements($before-top, $before-right, $before-bottom, $before-left) {
    content:"";
    position:absolute;
    top:$before-top;
    left:$before-left;
    right:$before-right;
    bottom:$before-bottom;
}

// icomoon usage
@mixin icon($content) {
    font-family: 'icomoon';
    content: $content;
    font-weight: normal;
}

// vertical alignment
@mixin inline($alignment) {
    display:inline-block;
    vertical-align:$alignment;
}

@mixin img-full-width {
    width: 100%;
    max-width: inherit;
    height: auto;
    display: block;
}

// font-size
@mixin font($size, $line: 1.25) {
    font-size: $size;
    line-height: $line;
}

@mixin text-color($color) {
    color: $color;
}

@mixin link-color($color) {
    color: $color;
    text-decoration: none;

    &:hover {
        color: darken($color, 15%);
        text-decoration: underline;
    }
}

@mixin link-color-underline($color) {
    color: $color;
    text-decoration: underline;

    &:hover {
        text-decoration: underline;
        color: darken($color, 15%);
    }
}

@mixin bg-hover($color) {
    background-color: $color;

    &:hover {
        background-color: darken($color, 10%);
    }
}

@mixin position($value) {
    top: $value;
    left: $value;
    right: $value;
    bottom: $value;
    position: absolute;
}

@mixin custom-icon($icon) {
  &:before {
      font-family: 'icomoon';
      content: $icon;
  }
}

//retina mixin
@mixin img-retina($image, $extension, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
       background-image: url('../images/' + $image + '@2x' + '.' + $extension);
       background-size: $width $height;
   }
}

@mixin letter-spacing($spacing) {
    letter-spacing: ($spacing / 1000) * 1em;
}

@mixin animate($properties, $duration: $animation-speed, $easing: $easing-default) {
    $list:();

    @each $prop in $properties {
        $str: #{$prop} #{$duration} #{$easing};
        $list: join($list, #{$str}, comma);
    }

    transition: $list;
}

@mixin link-decoration($color, $secondary-color) {
    color: $color;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.

    &:hover {
        color: $secondary-color;
        text-decoration: $link-hover-decoration;
    }
}

@mixin vertical-margin($vertical-margin) {
    margin-top: $vertical-margin;
    margin-bottom: $vertical-margin;
}

@mixin border-solid($border-width, $border-color) {
    border: $border-width solid $border-color !important;
}

@mixin opacity($opacity) {
    opacity: $opacity;
}
