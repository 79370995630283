@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';
@import '../base/variables';

// custom variables and override bootstrap variables
$primary:                           $orangePrincipal;

$theme-colors: ();
$theme-colors: map-merge((
        "primary":    $primary
), $theme-colors);

$base-min-width:                    320px;

// This is the default html and body font-size for the base rem value.
$rem-base:                          16px;

$body-bg:                           $lightGray;
$body-color:                        $black;

$box-shadow-sm:                     0 .125rem .25rem rgba($black, .5);

$grid-gutter-width:                 20px;

$open-sans:                         'Open Sans', sans-serif;
$roboto:                            'Roboto Slab', serif;
$verdana:                           'Verdana', Geneva, sans-serif;
$font-family-base:                  $open-sans;

$input-border-color:                $silver;
$input-placeholder-color:           $nobel;
$input-focus-border-color:          $silver;

$h1-font-size:                      44px;
$h2-font-size:                      18px;
$h3-font-size:                      14px;
$h4-font-size:                      12px;
$headings-color:                    $orangeSecondary;
$headings-font-family:              $roboto;

// Progress bars

$progress-height:                   15px;
$progress-font-size:                12px;
$progress-bg:                       $white;
$progress-border-radius:            15px;
$progress-bar-color:                $white;
$progress-bar-bg:                   $orangeSecondary;

// Navs

$nav-link-padding-y:                8px;
$nav-link-padding-x:                9px;
$nav-link-disabled-color:           $gray-600;

$nav-tabs-border-color:             rgba(0, 139, 151, 0.8);
$nav-tabs-border-width:             1px;
$nav-tabs-border-radius:            12px;
$nav-tabs-link-hover-border-color:  $white;
$nav-tabs-link-active-color:        $lagoon;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $white;

// Links

$link-decoration:                    underline;
$link-color:                         $orangePrincipal;
$link-hover-color:                   $orangePrincipalDark;
$link-hover-decoration:              none;


// Buttons

$btn-font-size:                      14px;
$btn-line-height:                    18px;
$btn-font-weight:                    700;
$btn-border-radius-sm:               20px;
$btn-border-radius:                  20px;
$btn-border-radius-lg:               25px;
$btn-transition:                     all 0.4s ease;
$btn-padding-y-xs:                   5px;
$btn-padding-x-xs:                   15px;
$btn-padding-y:                      5px;
$btn-padding-x:                      30px;

$input-font-size:                    14px;

@import '~bootstrap/scss/bootstrap';
