.visual {
    font: 24px/30px $roboto;
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    z-index: 2;
    color: $white;
    padding: 60px 0;
    text-align: center;
    margin: 0 0 20px;
    background-image: url('../../../../images/background_top.jpg');

    @include media('<desktop') {
        font-size: 18px;
        line-height: 24px;
    }

    @include media('<tablet') {
        font-size: 16px;
        padding: 40px 0 25px;
        margin: 0 0 12px;
    }

    &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: "";
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.65) 40%,
            rgba(0, 0, 0, 0) 100%
        );

        @include media('<tablet') {
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.35) 40%,
                rgba(0, 0, 0, 0) 100%
            );
        }
    }

    .container {
        position: relative;
        z-index: 5;
    }

    h1 {
        line-height: 1.0455;
        color: $white;
        margin: 0 0 10px;

        @include media('<tablet') {
            font-size: 16px;
            line-height: 24px;
            margin: 0;
        }
    }

    .visual-text {
        margin: 0 0 30px;

        @include media('<tablet') {
            font-weight: 400 !important;
        }
    }
}
