.applicants-block {
    overflow: hidden;
    margin: 0 0 20px;
    font-size: 14px;

    @include media('<tablet') {
        margin: 0 -15px 12px;
    }

    h2 {
        color: $lagoon;
        margin: 0 0 15px;
        font-weight: 700;
        text-transform: uppercase;

        @include media('<tablet') {
            font-size: 16px;
            margin: 0 0 10px;
        }
    }
}

.applicants-block-btn-wrap {
    overflow: hidden;
    text-align: center;
}
