.search-form-subpage {
    overflow: hidden;

    @include media('<desktop') {
        padding-bottom: 10px;
    }

    @include media('<tablet') {
        background: $white;
    }

    h2 {
        margin: 0 0 12px;
    }
}

.search-form-subpage-link {
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}
