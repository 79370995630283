.jcf-range {
    display: inline-block;
    min-width: 200px;
    width: 100%;
    height: 21px;
    overflow: hidden;
    margin: 0 0 10px;
}

.jcf-range .jcf-range-track {
    margin: 0 20px 0 0;
    position: relative;
    display: block;
}

.jcf-range .jcf-range-wrapper {
    background: #8b8b8b;
    border-radius: 5px;
    display: block;
    margin: 8px 1px;
    height: 5px;
}

.jcf-range.jcf-vertical {
    width: auto;
}

.jcf-range.jcf-vertical .jcf-range-wrapper {
    margin: 0;
    width: 10px;
    height: auto;
    padding: 20px 0 0;
}

.jcf-range.jcf-vertical .jcf-range-track {
    height: 180px;
    width: 10px;
}

.jcf-range.jcf-vertical .jcf-range-handle {
    left: -5px;
    top: auto;
}

.jcf-range .jcf-range-handle {
    position: absolute;
    background: $orangeSecondary;
    border-radius: 19px;
    width: 20px;
    height: 20px;
    margin: -8px 0 0;
    z-index: 1;
    top: 0;
    left: 0;

    &:before {
        position: absolute;
        left: -1000px;
        top: 8px;
        right: 0;
        height: 5px;
        content: '';
        border-radius: 5px;
        background: $orangeSecondary;
    }
}

.jcf-range .jcf-range-mark {
    position: absolute;
    overflow: hidden;
    background: #000;
    width: 1px;
    height: 3px;
    top: -7px;
    margin: 0 0 0 9px;
}

.jcf-range.jcf-vertical .jcf-range-mark {
    margin: 0 0 9px;
    left: 14px;
    top: auto;
    width: 3px;
    height: 1px;
}

.jcf-range.jcf-focus .jcf-range-handle {
    margin: -8px 0 0 -1px;
}

.jcf-range.jcf-disabled {
    background: none !important;
    opacity: 0.3;
}

/* common custom form elements styles */

.jcf-disabled {
    background: #ddd !important;
}

.jcf-focus,
.jcf-focus * {
    border-color: #f00 !important;
}
