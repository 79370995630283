.messages-block {
    overflow: hidden;
    margin: 0 0 20px;

    h2 {
        text-transform: uppercase;
        font-weight: 700;
        margin: 0 0 15px;

        @include media('<tablet') {
            margin: 0 0 10px;
        }
    }
}

.messages-block-btn-row {
    overflow: hidden;
    text-align: center;
    margin: 20px 0 20px;
}

.messages-block-link {
    display: inline-block;
    font-size: 14px;
    text-decoration: underline;
}

.messages-block-list {
    font-size: 14px;
    line-height: 18px;
    margin: 0;

    @include media('<tablet') {
        font-size: 12px;
    }

    li {
        margin: 0 0 15px;

        &:last-child {
            border: 0;
            margin: 0 0 7px;

            ul {
                margin: 0;
            }
        }
    }

    p {
        margin: 0;
    }

    ul {
        padding-left: 60px;
        margin: 0 0 13px;

        @include media('<desktop') {
            padding-left: 35px;
        }

        li {
            border: 0;
            margin: 0;
        }
    }
}

.messages-block-list-box {
    overflow: hidden;
}

.messages-block-list-title {
    font-size: 12px;
    display: block;
    color: $orangeSecondary;
    font-weight: 400;
}

.messages-block-list-link {
    overflow: hidden;
    text-align: right;
    margin: 0 0 3px;

    a {
        font-size: 12px;
        text-decoration: underline;
    }
}

.messages-block-input {
    min-height: 90px;
}

.messages-block-list-title-modified {
    color: $blueAlt;

    a {
        @include link-color($blueAlt);
    }
}
