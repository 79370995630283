.mobile-search-form {
    font: 14px/18px $open-sans;
    overflow: hidden;
    padding: 65px 10px;

    label {
        color: $white;
        display: block;
        text-align: center;
        margin: 0 0 6px;
        font-size: 12px;
    }

    .form-control {
        width: 100%;
        text-align: center;
    }

    .btn {
        width: 140px;
        display: block;
        margin: 0 auto;
    }
}

.mobile-search-form-wrap {
    display: block;
    width: 100%;
    margin: 0 0 11px;
}
