.filters-search {
    font: 14px/18px $open-sans;
    position: relative;
    background: rgba($black, 0.7);
    padding: 15px 19px;
    color: $white;
    left: 50%;
    transform: translateX(-50%);
    width: 820px;

    @include media('<desktop') {
        left: auto;
        transform: none;
        width: auto;
        margin: 0 auto;
        padding: 15px;
        max-width: 748px;
    }

    @include media('<tablet') {
        font-size: 12px;
        line-height: 16px;
        padding: 10px;
    }

    label {
        margin: 0 15px 0 0;

        @include media('<tablet') {
            display: block;
            text-align: center;
            margin: 0 0 0 -10px;
            font-size: 12px;
            max-width: 115px;
            min-width: 115px;
        }
    }

    .form-control {
        width: 200px;

        @include media('<desktop') {
            width: 165px;
        }

        @include media('<tablet') {
            width: 100%;
            min-width: 150px;
            text-align: center;
        }
    }
}

.filters-search-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @include media('<tablet') {
        width: auto;
        margin: 0 -10px;
        padding: 0 10px;
    }

    .btn {
        margin: 0 0 0 15px;

        @include media('<tablet') {
            margin: 0 auto;
            position: relative;
            right: -56px;
        }
    }
}

.filters-search-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include media('<tablet') {
        width: 100%;
        margin: 0 0 10px;
    }
}

.filters-search-filters {
    margin: 0;
    padding: 20px 0 5px;
    width: 100%;
    text-align: left;
    display: flex;
    flex-flow: row wrap;

    @include media('<tablet') {
        padding: 0;
    }
}

.filters-search-filters-opener {
    text-decoration: underline;
    color: $white;
    display: inline-block;

    @include media('<tablet') {
        font-size: 12px;
        margin: -20px 0 0 20px;
    }

    &:hover {
        color: $orangePrincipal;
    }
}

.filters-search-filters-slide {
    width: 100%;
}

.filters-search-filters-list {
    margin: 0 -3px;
    padding: 10px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start !important;

    @include media('<desktop') {
        margin: 0 -2px;
    }

    @include media('<tablet') {
        padding: 18px 0 0;
        margin: 0 -4px;
    }

    li {
        margin: 0 4px 10px;
        position: relative;

        @include media('<desktop') {
            margin: 0 2px 10px;
        }

        @include media('<tablet') {
            margin: 0 4px 10px;
        }

        .btn {
            border-radius: 5px;
            padding: 5px 10px;
            font-weight: 400;
            margin: 0;
            min-width: 52px;

            @include media('<desktop') {
                padding: 5px 8px;
            }

            @include media('<tablet') {
                font-size: 13px;
            }
        }

        &.active {
            .btn,
            .filters-search-filters-list-date-field {
                background: $orangeSecondary;
                color: $white;

                &::placeholder {
                    color: $white;
                }
            }
        }
    }
}

.filters-search-filters-link {
    text-decoration: underline;
    color: $white;

    &:hover {
        color: $orangePrincipal;
    }
}

.filters-search-filters-secondlast-item,
.filters-search-filters-last-item {
    .filters-search-filters-list-slide {
        left: auto;
        right: 0;
    }
}

.filters-search-filters-list-date-field {
    @include animate(all, 0.2s, ease-in-out);

    font-size: 14px;
    line-height: 18px;
    padding: 5px;
    width: 90px;
    text-align: center;
    border-radius: 5px;
    outline: none;
    background: transparent;
    color: $orangeSecondary;
    border: 1px solid $orangeSecondary;

    &::placeholder {
        color: $orangeSecondary;
        opacity: 1;
    }

    &:hover {
        background: $orangeSecondary;
        color: $white;

        &::placeholder {
            color: $white;
        }
    }
}

.filters-search-filters-list-slide {
    position: absolute;
    width: 240px;
    top: 100%;
    left: 0;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
}
