.header {
	position: relative;
	z-index: 99;
	padding: 3px 0 12px;
	background: $white;

	@include media('<tablet') {
		padding: 10px 0;
		background: $white;
	}
}

.header-logo {
	width: 119px;
	margin: 0 12px 0 0;

	@include media('<tablet') {
		width: 129px;
	}

	a {
		display: block;
	}

	img {
		@include full-width-img;
	}
}

.header-logo-area {
	padding: 2px 0 0 20px;

	@include media('<desktop') {
		padding: 2px 0 0;
	}

	@include media('<tablet') {
		width: 100%;
		margin: 0 auto;
		padding: 0;
		justify-content: center;
	}
}

.header-btns {
	margin: 0;
	padding: 5px 0 0;

	.header-btns-list {
		padding: 0 10px;

		@include media('<desktop') {
			padding: 0 5px;
		}
	}

	.btn {
		min-width: 140px;
		text-align: center;

		@include media('<desktop') {
			min-width: 120px;
			padding: 5px 15px;
		}
	}
}
