.entity-box-title {
    font-weight: 700;
    color: $extraDarkGray;
    text-transform: uppercase;
    font-size: $font-size-base;
}

.entity-box-canceled {
    opacity: 0.5;
    background-color: $silver;
}

.entity-box-started {
    opacity: 0.6;
}

.entity-box-holidays-session {
    //using important to overwrite bootstrap's "border" class
    border: 4px solid $orangePrincipalDark !important;
}
