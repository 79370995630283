.session-cover-container {
    overflow: hidden;
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
    max-height: 400px;
    display: flex;
    align-items: center;

    @include media('<desktop') {
        max-height: 250px;
    }

    @include media('<tablet') {
        max-height: 150px;
    }
}

.session-cover-container-not-available .session-cover-image {
    -webkit-filter: grayscale(50%);
    -moz-filter: grayscale(50%);
    -o-filter: grayscale(50%);
    -ms-filter: grayscale(50%);
    filter: grayscale(50%);
}

.session-cover-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    opacity: 1;
    z-index: 1;
    background: rgba(0,0,0,.5);
    padding: 5px 30px;
    width: max-content;

    @include media('<tablet') {
        font-size: 1.2rem;
    }
}

.session-cover-paris-image,
.session-cover-company-logo {
    position: absolute;
    width: 75px;
    height: 75px;
    z-index: 10;
    bottom: 20px;
    right: 20px;

    @include media('<desktop') {
        width: 50px;
        height: 50px;
        bottom: 10px;
        right: 10px;
    }

    @include media('<tablet') {
        width: 35px;
        height: 35px;
    }
}

.session-cover-company-logo {
    border-radius: 100%;
}
